import React,{useState,useEffect} from "react";
import { Side } from "./Side";
import "./Mon_profil.css";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import Swal from "sweetalert2";
export const Monprofil = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [formData, setFormData] = useState({
    nom: "",
    prenoms: "",
    genre: "",
    telephone: "",
    siteWeb:"",
    situaMat:"",
    datNais: "",
    email: "",
    adresse: "",
    codePostal: "",
    ville: "",
    nationalite: "",
    password: ""
  });
  const UserId = localStorage.getItem('userId');
  
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/usermodif/${UserId}/`) 
        .then((response) => {
          setFormData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }; 
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Utilisez Axios pour envoyer les données au serveur
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/usermodif/${UserId}/`, formData); 
      Toast.fire({
        title: "Mise à jour effectuée",
        icon: "success",
      });
      localStorage.setItem('telephone',response.data.telephone);
      localStorage.setItem('adresse',response.data.adresse);
      localStorage.setItem('email',response.data.email);
      localStorage.setItem('date_de_naissance',response.data.datNais);
      localStorage.setItem('situation_matrimoniale',response.data.situaMat);
      localStorage.setItem('site_web',response.data.siteWeb);
      localStorage.setItem('nationalité',response.data.nationalite);
    } catch (error) {
      Toast.fire({
        title: "Une erreur s'est produite lors de l'inscription",
        icon: "error",
      });
    }
  };
  return (
    <div>
      <Side />
      <div className="settings">
        <div className="settings__wrapper">
          <h2 className="settings__title">{formData.prenoms} {formData.nom}</h2>
          <hr />
          {/* <div className="settings__top">
            <button className="setting__btn active__btn">Profile</button>
            <button className="setting__btn">Projet professionnel</button>
            <button className="setting__btn">Paramètres</button> 
          </div> */}

          <div className="details__form">
            <h4 className="profile__title">Informations personnelles</h4>

            <form onSubmit={handleSubmit}>
              <div className="form__group">
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Nom </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nom"
                      autoFocus
                      value={formData.nom}
                      onChange={handleChange}
                      size="lg"
                      name="nom"
                    />
                  </Form.Group>
                </div>

                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="Prénom"
                      autoFocus
                      value={formData.prenoms}
                      onChange={handleChange}
                      name="prenoms"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="form__group">
                <div>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{ color: "#264a67" }}
                    >
                      Genre
                    </FormLabel>
                    <RadioGroup
                      value={formData.genre}
                      onChange={handleChange}
                      name="genre"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Femme"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Homme"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Téléphone</Form.Label>
                    <Form.Control
                      size="lg"
                      type="number"
                      placeholder="Numéro de téléphone"
                      autoFocus
                      value={formData.telephone}
                      onChange={handleChange}
                      name="telephone"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="form__group">
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Date de naissance</Form.Label>
                    <Form.Control
                      size="lg"
                      type="date"
                      placeholder="Date de naissance"
                      autoFocus
                      name="datNais"
                      value={formData.datNais}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="exemple@gmail.com"
                      autoFocus
                      size="lg"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              
              <div className="form__group">
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Adresse</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="adresse de résidence"
                      autoFocus
                      size="lg"
                      style={{ width: "350%" }}
                      name="adresse"
                      value={formData.adresse}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="form__group">
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Site web </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Site web"
                      autoFocus
                      value={formData.siteWeb}
                      onChange={handleChange}
                      size="lg"
                      name="siteWeb"
                    />
                  </Form.Group>
                </div>

                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Situation matrimoniale</Form.Label>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="Situation matrimoniale"
                      autoFocus
                      value={formData.situaMat}
                      onChange={handleChange}
                      name="situaMat"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="form__group">
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Code postal</Form.Label>
                    <Form.Control
                      size="lg"
                      type="number"
                      placeholder="code postal"
                      autoFocus
                      name="codePostal"
                      value={formData.codePostal}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Ville</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ville"
                      autoFocus
                      size="lg"
                      name="ville"
                      value={formData.ville}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Nationalité</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="nationalite"
                      autoFocus
                      size="lg"
                      name="nationalite"
                      value={formData.nationalite}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <Container>
                <Row className="justify-content-center">
                  <Col xs="auto">
                    <Button className="but" type="submit" onClick={handleSubmit}>Mettre à jour</Button>
                  </Col>
                </Row>
              </Container>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Monprofil;
