import React, { useState } from "react";
import "./Inscription.css";
import { NavLink, useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import Swal from "sweetalert2";
import { accountService } from "../../_services/account.service";
import { connect } from 'react-redux';
import { login } from '../../actions/auth';


const Connexion = ({ login, isAuthenticated }) => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState("");

  const onChange = (e) => {
    setLoginForm({
      ...loginForm, //Ancien état
      [e.target.name]: e.target.value, //Nouvel état
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const navigate = useNavigate();
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    login(loginForm.email, loginForm.password);
      
  };

  if (isAuthenticated) {
    Toast.fire({
      title: "Connexion réussie !",
      icon: "success",
    });
    navigate('/tableau');
  }

  return (
    <div className="container23">
      <div className="screen2" style={{ height: "500px" }}>
        <div className="screen__content" style={{ marginTop: "-20%" }}>
          <form className="login2" onSubmit={handleLoginSubmit}>
            <div className="login__field">
              <i className="login__icon bi bi-person-fill"></i>
              <input
                name="email"
                type="email"
                className="login__input"
                placeholder="Email"
                value={loginForm.email}
                onChange={onChange}
              />
            </div>
            <div className="login__field">
              <i className="login__icon bi bi-lock-fill"></i>
              <input
                name="password"
                type="password"
                className="login__input"
                placeholder="Password"
                value={loginForm.password}
                onChange={onChange}
              />
            </div>
            {loginError && <p>{loginError}</p>}
            <NavLink to="/forgetpassword" className="Con"> Mot de passe oublié</NavLink>
            <NavLink
              className="button login__submit2"
              onClick={handleLoginSubmit}
              style={{ marginTop: "30px" }}
            >
              <span className="button__text">Se connecter</span>
              <i className="button__icon bi bi-chevron-right"></i>
            </NavLink>
            <p className="" style={{ color: "#000" }}>
              Pas encore de compte ?
              <NavLink className="Con" to="/inscription">
                S'inscrire
              </NavLink>
            </p>
          </form>
        </div>
        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>
          <span className="screen__background__shape screen__background__shape3"></span>
          <span className="screen__background__shape screen__background__shape2"></span>
          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Connexion);
