import React,{useState,useEffect} from "react";
import styled from "styled-components";
import { MdAccountCircle } from "react-icons/md";
import { CiLogout } from "react-icons/ci";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from '../../../actions/auth';
import axios from "axios";
 const Navs = ({ logout, isAuthenticated }) => {
  const [userInfo, setUserInfo] = useState({});
 
  const UserId = localStorage.getItem('userId');
  
  useEffect(() => {
    if (UserId) {
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  return (
    <Nav>
      <div className="title">
        <h4>
          Bonjour <strong style={{ color: "#264a67" }}>{userInfo.prenoms} {userInfo.nom}</strong>
        </h4>
        <h1>
          Bienvenue sur le <span>TABLEAU DE BORD DE KAPITAL CV</span>
        </h1>
      </div>
      <div className="search">
        <NavLink to="/tableau/profil" style={{ color: "#000" }}>
          <MdAccountCircle style={{ fontSize: "40px" }} />
        </NavLink>
        <NavLink style={{ color: "#000" }} to="/">
          <CiLogout style={{ fontSize: "40px" }} onClick={() => logout()}/>
        </NavLink>
      </div>
    </Nav>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 20px;
  .title {
    margin-left: -5rem;    
    h1 {
      margin-top:20px;
      font-size: 20px;
      span {
        margin-left: 0.5rem;
        color: #ff6600;
        letter-spacing: 0.2rem;
      }
    }
  }
  .search {
    
    display: flex;
    align-items: center;
    gap: 1rem;
    
    border-radius: 1rem;
    
    
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    flex-direction: column;
    width:65%;
    .title {
      margin-top:75%;
      
      h1 {
        margin-left:-4%;
        span {
          font-size:15px;
          margin-left:2%;
          
        }
      }
    }
    .search {
      margin-top:-65%;
      margin-left:125%;
      font-size:20px;
      padding: 1rem;
      justify-content: center;
    }
  }
`;
export default connect(mapStateToProps, { logout })(Navs);
