import React, { useState } from "react";
import styled from "styled-components";
import { MdAccountCircle } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { Form, Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from '../../../actions/auth';
 const Navs2 = ({ logout, isAuthenticated,props }) => {
  const [show, setShow] = useState(false);
  const Handleshow = () => setShow(true);
  const Handleclose = () => setShow(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <Nav>
      <div className="title">
        <h4>
          Mes <strong style={{ color: "#264a67" }}>CV</strong>
        </h4>
        {/* <Form.Control
          type="text"
          placeholder="Rechercher un de mes CV"
          size="lg"
          style={{ width: "250%" }}
        /> */}
      </div>
      <div className="search">
        {/* <Button className="but" onClick={Handleshow}>
          Créer un CV
        </Button> */}
        <NavLink to="/tableau/profil" style={{ color: "#000" }}>
          <MdAccountCircle style={{ fontSize: "40px" }} />
        </NavLink>
        <NavLink style={{ color: "#000" }} to="/">
          <CiLogout style={{ fontSize: "40px" }} onClick={() => logout()}/>
        </NavLink>
      </div>

      <Modal
        show={show}
        onHide={Handleclose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Création de CV
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom de votre CV</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom du Cv"
                autoFocus
                required
              />
              <Form.Control.Feedback>Validé</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Poste recherché</Form.Label>
              <Form.Control
                type="text"
                placeholder="Poste recherché"
                autoFocus
                required
              />
              <Form.Control.Feedback>Validé</Form.Control.Feedback>
            </Form.Group>
            <p style={{ color: "#000" }}>
              Importer un CV existant (falcultatif )
            </p>
            <Form.Control type="file" required />
            <Form.Control.Feedback>Validé</Form.Control.Feedback>
            <NavLink to="/dashboard">
              <Button
                type="submit"
                style={{
                  backgroundColor: "#264a67",
                  color: "#fff",
                  border: "none",
                  marginTop: "30px",
                  marginLeft: "13O%",
                }}
              >
                Créer le document
              </Button>
            </NavLink>
          </Form>
        </Modal.Body>
      </Modal>
    </Nav>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 20px;
  .title {
    margin-left: -5rem;
    h4{
      margin-top:20px;
      font-size: 20px;
      margin-bottom:25%;
      span {
        margin-left: 0.5rem;
        color: #ff6600;
        letter-spacing: 0.2rem;
      }
    }
  }
  
  .search {
    
    display: flex;
    align-items: center;
    gap: 1rem;
    
    border-radius: 1rem;
    
    
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    flex-direction: column;
    .title {
        
      h4{
        margin-top:40%;
        font-size:25px;
        margin-left:4%;  
        
      }
    }
    .search {
      margin-top:-50%;
      margin-left:45%;
      font-size:20px;
      padding: 1rem;
      justify-content: center;
    }
  }
`;
export default connect(mapStateToProps, { logout })(Navs2);
