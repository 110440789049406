import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { cvService } from '../_services/cv.service';
import Axios from "../_services/caller.service"

export const Cv = () => {
    let navigate = useNavigate()
    const [cvs, setCvs] = useState([])
    const flag = useRef(false) //Pour empêcher le useEffect de s'exécuter à chaque action ou quelque chose comme ça

    //const {} = useQuery('users', cvService.getAllCvs)

    useEffect(() => {
        if (flag.current === false) {
            Axios.get('/api/protected_view/').then(
                response => {
                    cvService.getCvs(response.data.id).then(
                        res => {
                            console.log(res.data)
                            setCvs(res.data)
                        }
                    ).catch(err => console.log(err))
                    console.log(response.data)
                }
            )
        }

        return () => flag.current = true

    }, [])

    /* useEffect(() => {
        cvService.getAllCvs().then(
                    res => {
                        console.log(res.data)
                        setCvs(res.data)
                    }
                ).catch(err => console.log(err))
            

    }, []) */

    /* const marcel = (userId) => {
        console.log('click')
        navigate("../edit/" + userId)
    } */

    return (
        <div className="Cv">
            Cv list
            {/* <button onClick={() => marcel(4)}>Cv4</button> */}
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nom Cv</th>
                        <th>Date de dernière modification</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cvs.map(cv => (
                            <tr>
                                <td>{cv.id}</td>
                                <td>{cv.nomCv}</td>
                                <td>{cv.dat_dern_modif}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Cv;