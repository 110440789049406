import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./Inscription.css";
import { resend_activation } from '../../actions/auth';
import { useNavigate } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


const ResendActivationEmail = ({ resend_activation }) => {

    const navigate = useNavigate();

    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        resend_activation(email);
        setRequestSent(true);
    };

    if (requestSent) {
        return navigate('/');
    }
    return (
        <Container>
            <h1 className="text-black text-center fw-bold" style={{ marginTop: "25%" }}>Vous souhaitez obtenir un nouveau lien d'activation de compte ?</h1>
            <h6 className="d-flex justify-content-center align-items-center">Entrez votre adresse e-mail afin que nous puissions vous envoyer un autre lien.</h6>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={4} md={4} >

                        <form onSubmit={e => onSubmit(e)}>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                style={{ marginLeft: "100%" }}
                                className="p-3 mt-4"
                                value={email}
                                onChange={e => onChange(e)}
                                required
                            />
                            <Button style={{ marginLeft: "135%" }} className="p-2 mt-4 valide" type='submit'>Soumettre</Button>
                        </form>

                    </Grid>


                </Grid>

            </Box>

        </Container>
    );
};
export default connect(null, { resend_activation })(ResendActivationEmail);