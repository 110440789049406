import React from "react";
import Side from "./Side";
import Tab from "./Tab";

export const Tableau = () => {
  return (
    <div>
      <Side />
      <Tab />
    </div>
  );
};
export default Tableau;
