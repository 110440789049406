import React,{useState,useEffect} from "react";
import Navbars from "../Navbar/Navbar";
import {Side_lettre} from "./side_lettre";
import {Row,Col,Container} from "react-bootstrap";
import "../Dashboard/Dashboard.css";
import axios from "axios";
export const Motivation = ({downloadPDF}) => {
    const [bcolor, setBColor] = useState("#000");
    const [b2color, setB2Color] = useState("");
    const [isNameUppercase, setIsNameUppercase] = useState("");
    const [TextFont, setTextFont] = useState("");
  const [TextWeight, setTextWeight] = useState("");
  const [selectedTextFont, setSelectedTextFont] = useState("");
  const [selectedTextWeight, setSelectedTextWeight] = useState("");
    const HandleChangeColor = (color) => {
        setBColor(color);
        localStorage.setItem('bcolor',color);
    };
    useEffect(() => {
      const savedColor = localStorage.getItem('bcolor');
      if (savedColor) {
        setBColor(savedColor);
      }
    }, []);
    const HandleChangeColor2 = (color) => {
        setB2Color(color);
        localStorage.setItem('b2color',color);
    };
    useEffect(() => {
      const saved2Color = localStorage.getItem('b2color');
      if (saved2Color) {
        setB2Color(saved2Color);
      }
    }, []);
    useEffect(() => {
        const nameElement = document.querySelector(".tname");
        if (nameElement) {
          if (isNameUppercase) {
            nameElement.classList.add("uppercase");
          } else {
            nameElement.classList.remove("uppercase");
          }
        }
      }, [isNameUppercase]);
      useEffect(() => {
        const lettreContent = document.getElementById("lettre-content");
        if (lettreContent) {
          const textElements = lettreContent.querySelectorAll(".lettre-sheet");
    
          textElements.forEach((element) => {
            element.style.fontFamily = selectedTextFont;
          });
        }
      }, [selectedTextFont]);
      useEffect(() => {
        const lettreContent = document.getElementById("lettre-content");
        if (lettreContent) {
          const textElements = lettreContent.querySelectorAll(".lettre-sheet");
    
          textElements.forEach((element) => {
            element.style.fontWeight = selectedTextWeight;
          });
        }
      }, [selectedTextWeight]);
    
      useEffect(() => {
        const lettreContent = document.getElementById("lettre-content");
        if (lettreContent) {
          const textElements = lettreContent.querySelectorAll(".tname,.tobjet");
    
          textElements.forEach((element) => {
            element.style.fontFamily = TextFont;
          });
        }
      }, [TextFont]);
      useEffect(() => {
        const lettreContent = document.getElementById("lettre-content");
        if (lettreContent) {
          const textElements = lettreContent.querySelectorAll(".tname,.tobjet");
    
          textElements.forEach((element) => {
            element.style.fontWeight = TextWeight;
          });
        }
      }, [TextWeight]);
      const [textSize, setTextSize] = useState(16);
      const [text2Size, setText2Size] = useState(16);
      const handleTextFontChange = (font) => {
        setSelectedTextFont(font);
        localStorage.setItem('selectedTextFont', font);
      };
      const handleTextSizeChange = (font) => {
        
        setTextSize(font);
      }
      const handleText2SizeChange = (font) => {
        
        setText2Size(font);
      }
      const handleTextWeightChange = (font) => {
        setSelectedTextWeight(font);
        localStorage.setItem('selectedTextWeight', font);
      }
    
      const handleText2FontChange = (font) => {
        setTextFont(font);
        localStorage.setItem('TextFont', font);
      };
      const handleText2WeightChange = (font) => {
        setTextWeight(font);
        localStorage.setItem('TextWeight', font);
      }
      const UserId = localStorage.getItem('userId');
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  const [emailVisible, setEmailVisible] = useState(true);
  const [numVisible, setNumVisible] = useState(false);
  const [adresVisible, setAdresVisible] = useState(false);
  const handleCheckboxChange = () => {
    setEmailVisible(!emailVisible);
  };
  const handleCheckboxChange2 = () => {
    setNumVisible(!numVisible);
  };
  
  const handleCheckboxChange3 = () => {
    setAdresVisible(!adresVisible);
  }
    return (
        <div id="lettre-content" style={{overflow:"hidden"}}>
            <Row>
                <Col>
                    <Navbars />
                </Col>
                <Row>
                    <Col>
                        <Side_lettre
                            onCheckedChange={handleCheckboxChange}
                            onCheckedChange2={handleCheckboxChange2}
                            onCheckedChange3={handleCheckboxChange3}
                            onBgColorChange={HandleChangeColor}
                            onBg2ColorChange={HandleChangeColor2}
                            onMajuscule={setIsNameUppercase}
                            handleFontChange={handleTextFontChange}
                            handleWeightChange={handleTextWeightChange}
                            FontChange={handleText2FontChange}
                            WeightChange={handleText2WeightChange}
                            onTextSizeChange={handleTextSizeChange}
                            TextSizeChange={handleText2SizeChange}
                        />
                    </Col>
                </Row>
            </Row>
            <Row>
                <div className="container col-md-10 mb-5">
                    <div
                        className="lettre-template-sheet container pt-5 ps-4 pe-4 "
                        style={{
                          backgroundColor: "#ffffff",
                          boxShadow: "0 0 5px 0 #000",
                          marginTop:"10%"
                        }}
                    >
                        <Container className="lettre-sheet " style={{height:"29,7cm",fontFamily: selectedTextFont,
                            fontWeight: selectedTextWeight,fontSize: `${textSize}px`}}>
                            <div className="cv-header row pt-5 pb-5" style={{padding: "12%",fontSize: `${textSize}px`}}>
                                <div className={`tname ${ isNameUppercase ? "uppercase" : ""}`} style={{fontSize: `${text2Size}px`,fontFamily:TextFont,fontWeight:TextWeight}}>
                                    <span className="firstname " style={{color:b2color}}>{userInfo.nom}</span><br />
                                    <span className="lastname lastname">{userInfo.prenoms}</span>
                                </div>
                                <div data-mdb-sortable="sortable"
                                    className="container pt-3 gap-3">
                                    <div >
                                        
                                        {adresVisible && (<span className="fs-6">{userInfo.adresse}</span>)}
                                    </div>
                                    <div>
                                        
                                        {emailVisible && (<span className="fs-6">{userInfo.email}</span>)}
                                    </div>
                                    <div>
                                        
                                        {numVisible && (<span className="fs-6">{userInfo.telephone}</span>)}
                                    </div>

                                </div>
                            </div>
                            <hr className="pb-1"
                                style={{marginLeft: "12%", border: "none",marginRight:"35%",marginTop:"-5%",backgroundColor:bcolor}} />
                            <div className="cv-body" style={{fontSize: `${textSize}px`}}>
                                <div className="" style={{marginLeft: "10px"}}>
                                    <div>
                                        <div className="" style={{float:"right", marginRight: "10%"}}>
                                            <div className="">
                                                <input type="text" className="form-control text-right fw-bold" id="inputText form1"
                                                style={{border: "none",background: "none", direction: "rtl"}} 
                                                placeholder="Destinataire" />

                                            </div>
                                            <div className="">
                                                <input type="text" className="form-control text-right" id="inputText form1"
                                                style={{border: "none",background: "none", direction: "rtl"}}
                                                placeholder="Adresse entreprise" />
    
                                            </div>
                                            <div className="">
                                                <input className="form-control text-right" id="inputText form1"
                                                style={{border: "none",background: "none", direction: "rtl"}}
                                                placeholder="Code postal" />

                                            </div>
                                            <div className="">
                                                <input className="form-control text-right" id="inputText form1"
                                                style={{border: "none",background: "none", direction: "rtl"}}
                                                placeholder="Ville de Résidence" />

                                            </div>
                                            <div className="">
                                                <input className="form-control text-right" id="inputText form1"
                                                style={{border: "none",background: "none", direction: "rtl"}} 
                                                placeholder="Pays" />

                                            </div>
                                            <hr className="pb-1" 
                                            style={{border: "none",marginRight:"5%",marginTop:"10%",backgroundColor:bcolor}} />
                                            </div>


                                            <div className="mb-2" style={{marginTop: "5%"}}>
                                                <input type="text" className="form-control fw-semibold" name="" id="form1"
                                                    placeholder=" A Madame / Monsieur" style={{border: "none",background: "none"}} />
                                            </div>
                                            <div className="mb-3 d-flex tobjet" style={{fontSize: `${text2Size}px`,fontFamily:TextFont,fontWeight:TextWeight}}>
                                                <label for="exampleInputText" className="form-label">Objet: </label>
                                                <input type="text" className="form-control  fst-italic"
                                                    style={{border: "none", marginTop: "-5px", background: "none"}} id="form1 exempleInputText"
                                                    placeholder=" Le titre du poste pour lequel vous postulez" />
                                            </div>
                                            <div className="mt-5">
                                                <textarea className="form-control" id="textAreaExample"
                                                style={{border:"none"}}
                                                rows="6" placeholder="Saisissez un résumé de vos compétences et de votre expérience."></textarea>
                                            </div>
                                            
                                        </div>

                                    </div>

                                </div>
                        </Container>
                    </div>
                </div>
            </Row>
        </div>
    );
};
export default Motivation;