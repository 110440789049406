import React, { useState,useEffect } from "react";
import Navs from "./Navs/Navs";
import styled from "styled-components";
import image from "../Image/cv_defaut.jpg";
import image2 from "../Image/lettre3.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Axios from "../../_services/caller.service";
import { cvService } from "../../_services/cv.service";


export const Tab = (props) => {
  const [show, setShow] = useState(false);
  const Handleshow = () => setShow(true);
  const Handleclose = () => setShow(false);
  const [cvCreate, setCvCreate] = useState({
    nomCv: "",
    poste: "",
  });
  const [lettreCreate, setLettreCreate] = useState({
    nomLettre:"",
    poste:"",
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
 
  const UserId = localStorage.getItem('userId');
  //const cvId = localStorage.getItem('cvId');
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);

                  })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
     
        const currentDateTime = new Date().toISOString();
        cvService.createCv(
          cvCreate.nomCv,
          cvCreate.poste,
          currentDateTime,
          UserId,
          userInfo.email,
        ).then(
          res => {
            Axios.get("latest-doc/"+UserId+"/").then((result) => {
              cvService.saveCvId(result.data.latest_cv_id);
              axios.get(`${process.env.REACT_APP_API_URL}/cv/${result.data.latest_cv_id}/`)
              .then((response) => {
                localStorage.setItem('configurations', JSON.stringify(response.data.configurations));
              })
              .catch((error) => {
                console.error('Error fetching user info:', error);
              });

            })
            
          }
        ).catch(err => console.log(err));
        
        // Fonction qui retourne une promesse résolue lorsque l'information est disponible dans le localStorage
        function waitForLocalStorageKey(key) {
          return new Promise((resolve) => {
            function checkLocalStorage() {
              const data = localStorage.getItem(key);
              if (data !== null) {
                // L'information est disponible, résoudre la promesse avec la valeur
                resolve(data);
              } else {
                // L'information n'est pas encore disponible, réessayer après un court délai
                setTimeout(checkLocalStorage, 100); // Réessayer après 100 millisecondes (ajustez selon vos besoins)
              }
            }

            // Démarrer la vérification initiale
            checkLocalStorage();
          });
        }

        // Utilisation de la fonction waitForLocalStorageKey avec async/await
        async function votreFonctionAsynchrone() {
          try {
            // Attendre que l'information soit disponible dans le localStorage
            const info = await waitForLocalStorageKey('configurations');

            // À ce stade, l'information est disponible, vous pouvez exécuter votre action
            console.log('Information disponible :', info);

            Toast.fire({
              title: "Cv créé",
              icon: "success",
            });
            navigate("/dashboard");

          } catch (error) {
            console.error('Une erreur s\'est produite :', error);
          }
        }

        // Appeler votre fonction asynchrone
        votreFonctionAsynchrone();
      
    } catch (error) {
      Toast.fire({
        title: "Cv non créé !",
        icon: "error",
      });
    }
  };
  const [show2, setShow2] = useState(false);
  const Handleshow2 = () => setShow2(true);
  const Handleclose2 = () => setShow2(false);
  const handleSubmit2 = async (e) => {
    e.preventDefault();

    try {
     
        const currentDateTime = new Date().toISOString();
        cvService.createLettre(
          lettreCreate.nomLettre,
          lettreCreate.poste,
          currentDateTime,
          UserId,
          userInfo.email,
        ).then(
          res => {
            Axios.get("latest-doc/"+UserId+"/").then((result) => {
              cvService.saveLettreId(result.data.latest_lettre_id)
            })
          }
        ).catch(err => console.log(err));
        
        Toast.fire({
          title: "Lettre créé",
          icon: "success",
        });
        navigate("/lettre-motivation");
      
    } catch (error) {
      Toast.fire({
        title: "Une erreur s'est produite lors de l'inscription",
        icon: "error",
      });
    }
  };
  
  return (
    <Section>
      <Navs />

      <div className="top">
        <div className="gauche">
          <div className="editButton" onClick={Handleshow}>
            Créer un CV
          </div>
          <h1 className="title">Mes CV</h1>
          <div className="item">
            <img src={image} alt="" className="itemImg" />
            <div className="details">
              <p>Plongez dans l'univers de la création de CV et donnez vie à votre parcours 
                professionnel avec notre plateforme intuitive et personnalisable.</p>
              <center><Button className="bout">
                <NavLink className="boutt" to="/tableau/Mes_CV">
                  Liste de mes CV
                </NavLink>
              </Button></center>
            </div>
          </div>
        </div>
        {/* <div className="droite" style={{ width: "20px" }}>
          <div className="editButton" onClick={Handleshow2}>
            Créer une lettre
          </div>
          <h1 className="title">Mes Lettres</h1>
          <div className="item">
            <img src={image2} alt="" className="itemImg" />
            <div className="details let">
              <h1 className="itemTitle">Nom de la lettre</h1>
              <div className="detailItem">
                <span className="itemValue">Dernière modif</span>
              </div>
              <div className="detailItem">
                <span className="itemKey">Modifier</span>
                <span className="itemKey">Dupliquer</span>
                <span className="itemKey">Télécharger</span>
              </div>
              <Button className="bout">
                <NavLink className="boutt" to="/tableau/Mes_lettres">
                  Liste de mes lettres
                </NavLink>
              </Button>
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        show={show}
        onHide={Handleclose}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Création de CV
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom de votre CV</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom du Cv"
                autoFocus
                required
                name="nomCv"
                value={cvCreate.nomCv}
                onChange={(e) =>
                  setCvCreate({ ...cvCreate, nomCv: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Poste recherché</Form.Label>
              <Form.Control
                type="text"
                placeholder="Poste recherché"
                autoFocus
                required
                name="poste"
                value={cvCreate.poste}
                onChange={(e) =>
                  setCvCreate({ ...cvCreate, poste: e.target.value })
                }
              />
            </Form.Group>
            

            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                color: "#fff",
                border: "none",
                marginTop: "30px",
                marginLeft: "13O%",
              }}
              onClick={handleSubmit}
            >
              Créer le document
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={Handleclose2}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Création de Lettre
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit2}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom de votre Lettre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom de lettre"
                autoFocus
                required
                name="nomLettre"
                value={lettreCreate.nomLettre}
                onChange={(e) => setLettreCreate({...lettreCreate,nomLettre:e.target.value})}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Poste recherché</Form.Label>
              <Form.Control
                type="text"
                placeholder="Poste recherché"
                autoFocus
                required
                name="poste"
                value={lettreCreate.poste}
                onChange={(e) => setLettreCreate({...lettreCreate,poste:e.target.value})}
              />
            </Form.Group>

            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                color: "#fff",
                border: "none",
                marginTop: "30px",
                marginLeft: "13O%",
              }}
              onClick={handleSubmit2}
            >
              Créer le document
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Section>
  );
};
const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;
  
  .top {
    
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    .gauche {
      flex: 1;
      -webkit-box-shadow: 2px 4px 10px 1px #fff;
      box-shadow: 2px 4px 10px 1px #fff;
      padding: 20px;

      background-color:#fff;
      position: relative;

      .editButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 15px;
        text-decoration:none;
        color: #264a67;
        background-color: #7551f818;
        cursor: pointer;
        border-radius: 0px 0px 0px 5px;
      }

      .item {
        display: flex;
        gap: 20px;
        max-height: 10rem;
        overflow: hidden;
        .itemImg {
          object-fit: cover;
          height: 10rem;
          width: 10rem;
          object-fit: cover;
          border-radius: 5%;
          transition: 0.5s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .details {
          p{
            margin-bottom: 10px;
            color: #000;
            font-size: 22px;
          }
          
        }
      }
      .bout {
        background-color: #264a67;
        color: #fff;
        border: none;
        margin-top: 15px;
        text-align:center;
        justify-content:center;
        margin-left: 5px;
        opacity: 0.7;
        &:hover {
          opacity: 2;
        }
        .boutt {
          color: #fff;
          text-decoration:none;
        }
      }
    }
    .droite {
      flex: 2;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      position: relative;

      .editButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 15px;
        color: #264a67;
        background-color: #7551f818;
        cursor: pointer;
        border-radius: 0px 0px 0px 5px;
      }

      .item {
        display: flex;
        gap: 20px;
        max-height: 10rem;
        overflow: hidden;
        .itemImg {
          object-fit: cover;
          height: 10rem;
          width: 10rem;
          object-fit: cover;
          border-radius: 5%;
          transition: 0.5s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .details {
          .itemTitle {
            margin-bottom: 10px;
            color: #555;
            font-size: 25px;
          }

          .detailItem {
            margin-bottom: 10px;
            font-size: 14px;

            .itemKey {
              font-weight: bold;
              color: gray;
              margin-right: 5px;
            }

            .itemValue {
              font-weight: 300;
            }
          }
        }
      }
      .bout {
        background-color: #264a67;
        color: #fff;
        border: none;
        margin-top: 15px;
        margin-left: 35px;
        opacity: 0.7;
        &:hover {
          opacity: 2;
        }
        .boutt {
          color: #fff;
        }
      }
    }
    .title {
      font-size: 16px;
      color: black;
      margin-bottom: 20px;
    }
  }

  .tp {
    padding: 70px;

    display: flex;
    flex-direction: row;
    gap: 50px;

    .gauche {
      flex: 1;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      padding-right: 150px;

      position: relative;

      .editButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 15px;
        color: #264a67;
        background-color: #7551f818;
        cursor: pointer;
        border-radius: 0px 0px 0px 5px;
      }

      .item {
        display: flex;
        gap: 20px;
        max-height: 10rem;
        overflow: hidden;
        .itemImg {
          object-fit: cover;
          height: 10rem;
          width: 10rem;
          object-fit: cover;
          border-radius: 5%;
          transition: 0.5s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .details {
          width: 150%;
          .itemTitle {
            margin-bottom: 10px;
            color: #000;
            font-size: 18px;
            width: 150%;
          }

          .detailItem {
            margin-bottom: 10px;
            font-size: 14px;

            .itemKey {
              font-weight: bold;
              color: gray;
              margin-right: 5px;
            }

            .itemValue {
              font-weight: 300;
            }
          }
        }
      }
    }
    .droite {
      flex: 2;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      position: relative;

      .editButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 15px;
        color: #264a67;
        background-color: #7551f818;
        cursor: pointer;
        border-radius: 0px 0px 0px 5px;
      }

      .item {
        display: flex;
        gap: 20px;
        max-height: 10rem;
        overflow: hidden;
        .itemImg {
          object-fit: cover;
          height: 10rem;
          width: 10rem;
          object-fit: cover;
          border-radius: 5%;
          transition: 0.5s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .details {
          .itemTitle {
            margin-bottom: 10px;
            color: #555;
            font-size: 25px;
          }

          .detailItem {
            margin-bottom: 10px;
            font-size: 14px;

            .itemKey {
              font-weight: bold;
              color: gray;
              margin-right: 5px;
            }

            .itemValue {
              font-weight: 300;
            }
          }
        }
      }
    }
    .title {
      font-size: 16px;
      color: black;
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .top {
      margin-top:20%;
      padding: 20px;
      margin-left:-40%;
      width:155%;
      gap: 30px;
  
      .gauche {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;
  
        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 15px;
          color: #264a67;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }
  
        .item {
          display: flex;
          gap: 20px;
          max-height: 120%;
          flex-direction: column;
          align-items: center;
          text-align: center;
          .itemImg {
            object-fit: cover;
            height: 10rem;
            width: 10rem;
            object-fit: cover;
            border-radius: 5%;
            transition: 0.5s ease-in-out;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
  
          .details {
            flex-direction: column;
            align-items: center
             
            .detailItem {
              
            }
          }
        }
        .bout {
          background-color: #264a67;
          color: #fff;
          border: none;
          margin-top: 15px;
          margin-left: 35px;
          opacity: 0.7;
          &:hover {
            opacity: 2;
          }
          .boutt {
            color: #fff;
          }
        }
      }
    }
  }
`;
export default Tab;
