import React,{useState,useEffect} from "react";
import {Container,Button} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import image from "../Image/image1.jpg";
import { userService } from "../../_services/user.service";

export const Mail = () => {
 
  
    return(
        <Container >
            <h1 className="p-5 fs-3 text-black text-center fw-bold">Vérifiez votre boite email dès maintenant</h1>
            <div className="d-flex justify-content-center align-items-center">
                <img className="w-50" style={{height:'450px'}} src={image} alt="" />
            </div>
            <div>
              <p className=" pt-3 text-black d-flex justify-content-center align-items-center">Veuillez activer votre compte avant d'aller sur la page de connexion</p>
              <NavLink to="/connexion"style={{textDecoration:"none"}} className="d-flex justify-content-center align-items-center">
                <Button variant="outline-success" >Continuer</Button>
              </NavLink>
            </div>
        </Container>
    );
};
export default Mail; 