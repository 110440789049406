import axios from "axios";

export function deleteexperience(id) {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/sbExpPro/` + id + `/`)
    .then((res) => {
      return res.data;
    });
}
export function deletediplome(id) {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/sbDipForm/` + id + `/`)
    .then((res) => {
      return res.data;
    });
}
export function deletecompetence(id) {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/sbCompetence/` + id + `/`)
    .then((res) => {
      return res.data;
    });
}
export function deletecentreinteret(id) {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/sbCentreInteret/` + id + `/`)
    .then((res) => {
      return res.data;
    });
}
export function deletelangue(id) {
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/langue/` + id + `/`)
    .then((res) => {
      return res.data;
    });
}
