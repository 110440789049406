import React,{useState,useEffect} from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { MDBRange } from 'mdb-react-ui-kit';
export const Competence = ({handleClose}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "center",

    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const UserId = localStorage.getItem('userId');
  const [formData, setFormData] = useState({
    competence: '',
    niveauAtout: '',
    details:'',
    customUser:UserId
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  

const comp_id = localStorage.getItem('Competence_id')
useEffect(() => {
  if (UserId) {
    // Si l'ID du CV existe dans le local storage
    axios.get(`${process.env.REACT_APP_API_URL}/sbCompetence/${comp_id}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
      });
  }
}, [UserId]);
  const handleUpdate = (e) => {
    e.preventDefault();

    axios
      .put(`${process.env.REACT_APP_API_URL}/sbCompetence/${comp_id}/`, formData)
      .then((response) => {
        // Traitez la réponse du serveur ici
        Toast.fire({
          title: "Compétence modifiée",
          text:"Veuillez actualiser la page",
          icon: "success",
        });
       
        setFormData({
          competence: '',
          niveauAtout: '',
          details:'',
        });
        // Fermez le modal après la soumission
        handleClose();
      })
      .catch((error) => {
        // Gérez les erreurs ici
        Toast.fire({
          title: "Une erreur s'est produite lors de la modification",
          icon: "error",
        });
        
      });
  };
  return (
    <Form onSubmit={handleUpdate}>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Compétence</Form.Label>
        <Form.Control type="text" placeholder="Compétence" 
          name="atout" value={formData.atout} onChange={handleChange}/>
      </Form.Group>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Niveau</Form.Label>
          <MDBRange  name="niveauAtout" value={formData.niveauAtout} onChange={handleChange}/> 
      </Form.Group>
      <Form.Group controlId="formDescription">
        <Form.Label className="fw-bold">Description</Form.Label>
        <Form.Control type="text" placeholder="Niveau Compétence" 
          name="niveauAtout" value={formData.details} onChange={handleChange}/>
      </Form.Group>
      <Button
            type="submit"
            style={{
              backgroundColor: "#264a67",
              border: "none",
              marginTop:"10px"
            }}
            onClick={handleUpdate}
          >
            Modifier
          </Button>
    </Form>
  );
};
export default Competence;
