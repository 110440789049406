import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Side } from "../Side";
import Navs2  from "../Navs/Navs2";
import { BsFillClockFill } from "react-icons/bs";
import image from "../../Image/template2.png";
import Axios from "../../../_services/caller.service";
import { cvService } from "../../../_services/cv.service";
import Ima from "../../Image/cv_defaut.jpg";
//import { format } from 'date-fns';
import { connect } from 'react-redux';
import { load_user } from "../../../actions/auth";
import axios from "axios";

const Mescv = ({ load_user, isAuthenticated, user,downloadPDF }) => {
  let navigate = useNavigate()
  const [cvs, setCvs] = useState([])
  const flag = useRef(false) //Pour empêcher le useEffect de s'exécuter à chaque action ou quelque chose comme ça
  const [userInfo, setUserInfo] = useState({});
 
  const UserId = localStorage.getItem('userId');
  //const cvId = localStorage.getItem('cvId');
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  
  useEffect(() => {
    if (flag.current === false) {

      cvService.getCvs(UserId).then(
        res => {
          console.log(res.data)
          setCvs(res.data)
        }
      ).catch(err => console.log(err))
    }
    return () => flag.current = true
  }, [])

  const handleClick = (id) => {
    cvService.saveCvId(id)
    axios.get(`${process.env.REACT_APP_API_URL}/cv/${id}/`)
        .then((response) => {
          localStorage.setItem('configurations', JSON.stringify(response.data.configurations));
          //localStorage.setItem('modelCv', response.data.modelcv);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });

        // Fonction qui retourne une promesse résolue lorsque l'information est disponible dans le localStorage
function waitForLocalStorageKey(key) {
  return new Promise((resolve) => {
    function checkLocalStorage() {
      const data = localStorage.getItem(key);
      if (data !== null) {
        // L'information est disponible, résoudre la promesse avec la valeur
        resolve(data);
      } else {
        // L'information n'est pas encore disponible, réessayer après un court délai
        setTimeout(checkLocalStorage, 100); // Réessayer après 100 millisecondes (ajustez selon vos besoins)
      }
    }

    // Démarrer la vérification initiale
    checkLocalStorage();
  });
}

  // Utilisation de la fonction waitForLocalStorageKey avec async/await
  async function votreFonctionAsynchrone() {
    try {
      // Attendre que l'information soit disponible dans le localStorage
      const waitForConfigStorage = await waitForLocalStorageKey('configurations');
      //const waitForModelStorage = await waitForLocalStorageKey('modelCv');


      // À ce stade, l'information est disponible, vous pouvez exécuter votre action
      console.log('Information disponible :', waitForConfigStorage);
      //console.log('Information disponible :', waitForModelStorage);

      navigate("/dashboard")
    } catch (error) {
      console.error('Une erreur s\'est produite :', error);
    }
  }

  // Appeler votre fonction asynchrone
  votreFonctionAsynchrone();

  };

  if (!isAuthenticated) {
    return navigate('/connexion');
  }

  return (
    <div>
      <Side />
      <Section>
        <Navs2 />

        {
          cvs.map(cv => (
            <div className="top">
              <div className="left">
                <div className="item">
                  <img
                    src={Ima}
                    alt=""
                    className="itemImg"
                    onClick={() => handleClick(cv.id)}
                  />
                  <div className="details">
                    <Row>
                      <Col>
                        <Container style={{ marginRight: "600px" }}>
                          <Row>
                            <Col>
                              <h6 className="fw-bold">{cv.nomCv}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h6>Poste: {cv.poste}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h6>Langue: {cv.langue_cv}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="detailItem">
                                <BsFillClockFill />
                                <span className="itemKey">Dernière modification: {cv.dat_dern_modif} {/* {format(new Date(cv.dat_dern_modif), "yyyy-MM-dd 'à' HH:mm:ss")} */}</span>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Col>
                      <Col>

                        {/* <Button className="bouton">Modifier</Button>

                        <Button className="bouton">Dupliquer</Button>

                        <Button className="bouton">Supprimer</Button> */}

                        <Button className="bouton" onClick={() => handleClick(cv.id)}>Modifier</Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </Section>
    </div>
  );
};



const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;

  .top {
    margin-left: -5vw;
    height: 80%;
    padding: 20px;
    display: flex;
    flex-direction: row;

    .left {
      flex: 1;
      -webkit-box-shadow: 2px 4px 10px 1px #fff;
      box-shadow: 2px 4px 10px 1px #fff;
      padding: 40px;
      background-color:#fff;
      position: relative;

      .item {
        display: flex;
        gap: 20px;
        max-height: 20rem;
        overflow: hidden;
        
        .itemImg {
          object-fit: cover;
          height: 15rem;
          width: 10rem;
          object-fit: cover;
          border-radius: 5%;
          transition: 0.5s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .details {
          .itemTitle {
            margin-bottom: 10px;
            color: #555;
            font-size: 25px;
          }
          .bouton {
            background-color: transparent;
            color: #264a67;
            border: 1px solid #264a67;
            margin-bottom: 10px;
            margin-left:10px;
            font-size: 12px;
            &:hover {
              background-color: #264a67;
              color: #fff;
              border: none;
            }
          }

          .detailItem {
            font-size: 14px;
            svg {
              margin-right: 10px;
            }
            .itemKey {
              font-weight: bold;
              color: gray;
              margin-right: 5px;
            }

            .itemValue {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .top {
      padding: 20px;
      margin-top:5%;
      margin-left:-40%;
      width:155%;
      gap: 30px;
      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 40px;
        position: relative;
  
        .item {
          display: flex;
          gap: 20px;
          max-height: 120%;
          flex-direction: column;
          align-items: center;
          text-align: center;
          .itemImg {
            object-fit: cover;
            height: 15rem;
            width: 10rem;
            object-fit: cover;
            border-radius: 5%;
            transition: 0.5s ease-in-out;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
  
          .details {
            .itemTitle {
              margin-bottom: 10px;
              color: #555;
              font-size: 25px;
            }
            .bouton {
              background-color: transparent;
              color: #264a67;
              border: 1px solid #264a67;
              margin-bottom: 10px;
              margin-left:10px;
              font-size: 12px;
              &:hover {
                background-color: #264a67;
                color: #fff;
                border: none;
              }
            }
  
            .detailItem {
              display:none;
              font-size: 10px;
              margin-left:5%;
              svg {
                margin-right: 10px;
              }
              .itemKey {
                font-weight: bold;
                color: gray;
                
              }
  
              .itemValue {
                font-weight: 300;
              }
            }
          }
        }
      }
    }
`;

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(mapStateToProps, { load_user })(Mescv);
