import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Side } from "../Side";
import  Navs3  from "../Navs/Navs3";
import { cvService } from "../../../_services/cv.service";
import image from "../../Image/lettre3.jpg";
import image2 from "../../Image/im.jpeg";
import { BsFillClockFill } from "react-icons/bs";
import { connect } from 'react-redux';
import { load_user } from "../../../actions/auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Meslettres = ({ load_user, isAuthenticated, user,downloadPDF }) => {
  let navigate = useNavigate()
  const [lettres, setLettres] = useState([])
  const flag = useRef(false) //Pour empêcher le useEffect de s'exécuter à chaque action ou quelque chose comme ça
  const [userInfo, setUserInfo] = useState({});
 
  const UserId = localStorage.getItem('userId');
  //const cvId = localStorage.getItem('cvId');
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  useEffect(() => {
    if (flag.current === false) {

      cvService.getLettres(UserId).then(
        res => {
          console.log(res.data)
          setLettres(res.data)
        }
      ).catch(err => console.log(err))
    }
    return () => flag.current = true
  }, [])

  const handleClick = (id) => {
    cvService.saveCvId(id)
    navigate("/lettre-motivation")
  };

  if (!isAuthenticated) {
    return navigate('/connexion');
  }

  return (
    <div>
      <Side />
      <Section>
        <Navs3 />
        {lettres.map(lettre => (
        <div className="top">
          <div className="left">
            <div className="item">
              <img src={image} alt="" className="itemImg" onClick={() => handleClick(lettre.id)}/>
              <div className="details">
                <Row>
                  <Col>
                    <Container style={{ marginRight: "600px" }}>
                      <Row>
                        <Col>
                          <h6 className="fw-bold">{lettre.nomLettre}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>{lettre.poste}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6>{lettre.langue_lettre}</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="detailItem">
                            <BsFillClockFill />
                            <span className="itemKey">Dernière modification : {lettre.dat_dern_modif}</span>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col>
                    
                          <Button className="bouton" onClick={() => handleClick(lettre.id)}>Modifier</Button>
                       
                  </Col>
                </Row>
                
              </div>
            </div>
          </div>
        </div>
        ))}
      </Section>
    </div>
  );
};
const Section = styled.section`
  margin-left: 18vw;
  padding: 2rem;
  height: 100%;

  .top {
    margin-left: -5vw;
    height: 80%;
    padding: 20px;
    display: flex;
    flex-direction: row;

    .left {
      flex: 1;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 40px;
      position: relative;

      .editButton {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        font-size: 15px;
        color: #264a67;
        background-color: #7551f818;
        cursor: pointer;
        border-radius: 0px 0px 0px 5px;
      }

      .item {
        display: flex;
        gap: 20px;
        max-height: 20rem;
        overflow: hidden;
        .itemImg {
          object-fit: cover;
          height: 15rem;
          width: 10rem;
          object-fit: cover;
          border-radius: 5%;
          transition: 0.5s ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .details {
          .itemTitle {
            margin-bottom: 10px;
            color: #555;
            font-size: 25px;
          }
          .bouton {
            background-color: transparent;
            color: #264a67;
            border: 1px solid #264a67;
            margin-bottom: 10px;
            font-size: 12px;
            &:hover {
              background-color: #264a67;
              color: #fff;
              border: none;
            }
          }

          .detailItem {
            font-size: 14px;
            svg {
              margin-right: 10px;
            }
            .itemKey {
              font-weight: bold;
              color: gray;
              margin-right: 5px;
            }

            .itemValue {
              font-weight: 300;
            }
          }
        }
      }
    }
  }
`;
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
});
export default connect(mapStateToProps, { load_user })(Meslettres);
