import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import  Navbars  from "../Navbar/Navbar";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import Modele1 from "./Model1";
import Modele2 from "./Model2";
import Modele3 from "./Model3";
//import { userService } from "../../_services/user.service";
//import Axios from "../../_services/caller.service";
import {connect} from "react-redux";
import { load_user } from "../../actions/auth";
import { login } from "../../actions/auth";
import axios from "axios";
export const Dashboard = ({ downloadPDF,user,load_user,isAuthenticated,login }) => {
  const navigate = useNavigate();
  if (isAuthenticated) {
    return   navigate('/connexion');
  }
  var config = JSON.parse(localStorage.getItem('configurations'));
  //const modelCv = localStorage.getItem('modelCv')//.replace(/"/g, '') //On élimine les guillemets ("") supeflus encadrant la valeur
  
  const [bgColor, setBgColor] = useState(config.Mise_en_page.Couleur1);
  const [bg2Color, setBg2Color] = useState(config.Mise_en_page.Couleur2);
  const [bg3Color, setBg3Color] = useState(config.Mise_en_page.Couleur3);
  const [bg4Color, setBg4Color] = useState(config.Mise_en_page.Couleur4);
  const [selectedTextFont, setSelectedTextFont] = useState(config.Mise_en_page.Police_texte);
  const [selectedTextWeight, setSelectedTextWeight] = useState(config.Mise_en_page.Format_texte);
  const [TextFont, setTextFont] = useState(config.Mise_en_page.Police_titre);
  const [TextWeight, setTextWeight] = useState(config.Mise_en_page.Format_titre);
  const [selectedTemplate, setSelectedTemplate] = useState("Modele1");
  //alert(selectedTemplate)
  const [emailData, setEmailData] = useState({
    email: "",
    photo:""
  });
  const [posteData, setPosteData] = useState({
    poste: "",
  });

  const [inputPoste, setInputPoste] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const handleBg1ColorChange = (color) => {
    setBg1Color(color);
  };
 
  const [userInfo, setUserInfo] = useState({});
   const UserId = localStorage.getItem('userId');
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
          setEmailData(response.data);
          setInputEmail(response.data.email)
          setImageUrl(response.data.photo)
          setInputAdresse(response.data.adresse)
          setInputDatNais(response.data.datNais)
          setInputNationalite(response.data.nationalite)
          setInputPhone(response.data.telephone)
          setInputSite(response.data.siteWeb)
          setInputSituation(response.data.situaMat)
          localStorage.setItem('mail',response.data.email);

        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  const [cv, setCv] = useState([]);
  const cvId = localStorage.getItem('cvId');
    useEffect(() => {
    if (cvId) {
      axios.get(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`)
        .then((response) => {
          setCv(response.data);
          localStorage.setItem('poste',response.data.poste);
          setPosteData(response.data);
          setInputPoste(response.data.poste);
          //localStorage.setItem('configurations', JSON.stringify(response.data.configurations));
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [cvId]);
  function updateLayoutInDB(value){
    axios.patch(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`, {configurations: value});
  }


  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    localStorage.setItem("modeleCv", template); 
    
    //Mette à jour dans la bdd
    //axios.patch(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`, {modelcv: template});
  };
  const handleBgColorChange = (color) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Couleur1 = color;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setBgColor(color);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    localStorage.setItem('bgColor', color);

  };
  const handleBg2ColorChange = (color) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Couleur2 = color;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setBg2Color(color);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    localStorage.setItem('bg2Color', color);
  };
  const handleBg3ColorChange = (color) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Couleur3 = color;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setBg3Color(color);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    localStorage.setItem('bg3Color', color);
  };
  const handleBg4ColorChange = (color) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Couleur4 = color;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setBg4Color(color);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    localStorage.setItem('bg4Color', color);
  };  
  const [isPhotoRounded, setIsPhotoRounded] = useState(config.Mise_en_page.Photo_arrondie);
  const [isNameUppercase, setIsNameUppercase] = useState(config.Mise_en_page.Nom_majuscule);
   
  // Mettre à jour la valeur de isPhotoRounded et la stocke dans le localStorage
  useEffect(() => {
    if (isPhotoRounded) {
      const photoElement = document.querySelector(".profile-photo2,.profile-photo");
      photoElement.classList.add("rounded");
      
    } else {
      const photoElement = document.querySelector(".profile-photo2,.profile-photo");
      photoElement.classList.remove("rounded");
      
    }
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Photo_arrondie = isPhotoRounded;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setIsPhotoRounded(isPhotoRounded);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    localStorage.setItem('isPhotoRounded', JSON.stringify(isPhotoRounded));
  }, [isPhotoRounded]);

  // Mettre à jour la valeur de isNameUppercase et la stocke dans le localStorage
  useEffect(() => {
    if (isNameUppercase) {
      const nameElement = document.querySelector(".nom");
      nameElement.classList.add("uppercase");
    } else {
      const nameElement = document.querySelector(".nom");
      nameElement.classList.remove("uppercase");
    }
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Nom_majuscule = isNameUppercase;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setIsNameUppercase(isNameUppercase);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    localStorage.setItem('isNameUppercase', JSON.stringify(isNameUppercase));
  }, [isNameUppercase]);
  useEffect(() => {
    const cvContent = document.getElementById("cv-content");
    if (cvContent) {
      const textElements = cvContent.querySelectorAll(".changeable-text");

      textElements.forEach((element) => {
        element.style.fontFamily = selectedTextFont;
      });
    }
  }, [selectedTextFont]);
  useEffect(() => {
    const cvContent = document.getElementById("cv-content");
    if (cvContent) {
      const textElements = cvContent.querySelectorAll(".changeable-text");

      textElements.forEach((element) => {
        element.style.fontWeight = selectedTextWeight;
      });
    }
  }, [selectedTextWeight]);

  useEffect(() => {
    const cvContent = document.getElementById("cv-content");
    if (cvContent) {
      const textElements = cvContent.querySelectorAll(".changeable-text");

      textElements.forEach((element) => {
        element.style.fontFamily = TextFont;
      });
    }
  }, [TextFont]);
  useEffect(() => {
    const cvContent = document.getElementById("cv-content");
    if (cvContent) {
      const textElements = cvContent.querySelectorAll(".changeable-text");

      textElements.forEach((element) => {
        element.style.fontWeight = TextWeight;
      });
    }
  }, [TextWeight]);
  const [textSize, setTextSize] = useState(16);
  const [text2Size, setText2Size] = useState(16);
  const handleTextFontChange = (font) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Police_texte = font;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setSelectedTextFont(font);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    
    localStorage.setItem('selectedTextFont', font);
  };
  const handleTextSizeChange = (font) => {
    
    setTextSize(font);
  }
  const handleText2SizeChange = (font) => {
    
    setText2Size(font);
  }
  const handleTextWeightChange = (font) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Format_texte = font;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setSelectedTextWeight(font);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    
    localStorage.setItem('selectedTextWeight', font);
  }

  const handleText2FontChange = (font) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Police_titre = font;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setTextFont(font);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    
    localStorage.setItem('TextFont', font);
  };
  const handleText2WeightChange = (font) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Mise_en_page.Format_titre = font;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    setTextWeight(font);
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    
    localStorage.setItem('TextWeight', font);
  }
  
  
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageSrc = e.target.result;
        setImageUrl(imageSrc);
        const formData = new FormData();
        formData.append('photo', file);

        // Vous pouvez également ajouter d'autres champs à formData si nécessaire
        // formData.append('nom', 'valeur');

        axios.patch(`${process.env.REACT_APP_API_URL}/photoUpdate/${UserId}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        // Enregistrez l'URL de l'image dans le localStorage
        localStorage.setItem('selectedImage', imageSrc);

      };

      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    // Récupérez l'URL de l'image depuis le localStorage lors du chargement de la page
    const savedImage = localStorage.getItem('selectedImage');

    if (savedImage) {
      setImageUrl(savedImage);
    }
  }, []);
  const cvposte = posteData.poste;
  const userEmail = emailData.email;
  const [inputPhone, setInputPhone] = useState('');
  const handleInputChange = (text) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.informations.telephone = text;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    setInputPhone(text);
    localStorage.setItem('telephone',text);
  };
  
  const handleInputChange1 = (text) => {
    setInputPoste(text);
    localStorage.setItem('new_poste',text);
  };
  const handleInputChange2 = (text2) => {
    setInputEmail(text2);
    localStorage.setItem('email',text2);
  };
  const [inputAdresse, setInputAdresse] = useState("");
  const handleInputChange3 = (text) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.informations.adresse = text;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    setInputAdresse(text);
    localStorage.setItem('adresse',text);
    
  };
  const [inputDatNais, setInputDatNais] = useState('');
  const handleInputChange4 = (text) => {
  var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.informations.dateNaissance = text;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    setInputDatNais(text);
    localStorage.setItem('date_de_naissance',text);
  };
  let situaMatrimoniale_value = ''
  if (config.informations.situaMatrimoniale){
    situaMatrimoniale_value = config.informations.adresse
  }else{
    situaMatrimoniale_value = userInfo.situaMat
  }
  const [inputSituation, setInputSituation] = useState('');
  const handleInputChange5 = (text) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.informations.situaMatrimoniale = text;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    setInputSituation(text);
    localStorage.setItem('situation_matrimoniale',text);
  }; 
  const [inputSite, setInputSite] = useState('');
  const handleInputChange6 = (text) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.informations.siteWeb = text;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    setInputSite(text);
    localStorage.setItem('site_web',text);
  };
  const [inputNationalite, setInputNationalite] = useState('');
  const handleInputChange7 = (text) => {
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.informations.Nationalite = text;
    localStorage.setItem("configurations", JSON.stringify(configjson));
    //Mette à jour dans la bdd
    updateLayoutInDB(configjson);
    setInputNationalite(text);
    localStorage.setItem('nationalité',text);
  };
  useEffect(() => {
    // Récupérer les données depuis le localStorage lors du chargement de la page
    
    const saved1 = localStorage.getItem('new_poste');
    if (saved1) {
      setInputPoste(saved1);
    }
    const saved2 = localStorage.getItem('email');
    if (saved2) {
      setInputEmail(saved2);
    }
    const savedTemplate = localStorage.getItem('modeleCv');
    if (savedTemplate) {
      setSelectedTemplate(savedTemplate);
    }
  }, []);

  var config = JSON.parse(localStorage.getItem('configurations'));

  const [emailVisible, setEmailVisible] = useState(config.Visibilite_informations.email_visible);
  const [numVisible, setNumVisible] = useState(config.Visibilite_informations.telephone_visible);
  const [adresseVisible, setAdresseVisible] = useState(config.Visibilite_informations.adresse_visible);
  const [datVisible, setDatVisible] = useState(config.Visibilite_informations.dateNaissance_visible);
  const [natioVisible, setNatioVisible] = useState(config.Visibilite_informations.Nationalite_visible);
  const [siteVisible, setSiteVisible] = useState(config.Visibilite_informations.siteWeb_visible);
  const [situaVisible, setSituaVisible] = useState(config.Visibilite_informations.situaMatrimoniale_visible);

  
  const handleCheckboxChange = () => {
    setEmailVisible(!emailVisible);
  };
  const handleCheckboxChange2 = () => {
    setNumVisible(!numVisible);
  };

  const handleCheckboxChange3 = () => {
    setAdresseVisible(!adresseVisible);
  };
  const handleCheckboxChange4 = () => {
    setDatVisible(!datVisible);
  };
  const handleCheckboxChange5 = () => {
    setSituaVisible(!situaVisible);
  };
  const handleCheckboxChange6 = () => {
    setSiteVisible(!siteVisible);
  }
  const handleCheckboxChange7 = () => {
    setNatioVisible(!natioVisible);
  }
  return (
    <div style={{ backgroundColor: "#dfdeea",overflow:"hidden",overflowY:"auto" }}>
      <div className="d-flex">
        <div className="w-auto">
          <Sidebar
            onBgColorChange={handleBgColorChange}
            onBg1ColorChange={handleBg1ColorChange}
            onBg2ColorChange={handleBg2ColorChange}
            onBg3ColorChange={handleBg3ColorChange}
            onBg4ColorChange={handleBg4ColorChange}
            onPhotoRoundedChange={setIsPhotoRounded}
            onMajuscule={setIsNameUppercase}
            handleFontChange={handleTextFontChange}
            FontChange={handleText2FontChange}
            handleWeightChange={handleTextWeightChange}
            WeightChange={handleText2WeightChange}
            onFileChange={handleFileChange}
            handleTemplateSelect={handleTemplateSelect}
            onTextSizeChange={handleTextSizeChange}
            text2Size={handleText2SizeChange}
            onInputChange={handleInputChange}
            onInputChange1={handleInputChange1}
            onInputChange2={handleInputChange2}
            onInputChange3={handleInputChange3}
            onInputChange4={handleInputChange4}
            onInputChange5={handleInputChange5}
            onInputChange6={handleInputChange6}
            onInputChange7={handleInputChange7}
            handleCheck1={handleCheckboxChange}
            handleCheck3={handleCheckboxChange2}
            handleCheck5={handleCheckboxChange3}
            handleCheck7={handleCheckboxChange4}
            handleCheck9={handleCheckboxChange5}
            handleCheck11={handleCheckboxChange6}
            handleCheck13={handleCheckboxChange7}
            
          />
        </div>
        <div className="col">
          <Navbars />
        </div>
      </div>
      <div className="row" style={{overflowY:"hidden"}}>
        <div id="cv-container" className=" container col-md-10 mt-5 mb-5" >
          <div className="cv-template-sheet">
            {selectedTemplate === "Modele1" && (
              <Modele1
                bgColor={bgColor}
                bg2Color={bg2Color}
                bg3Color={bg3Color}
                bg4Color={bg4Color}
                imageUrl={imageUrl}
                isPhotoRounded={isPhotoRounded}
                isNameUppercase={isNameUppercase}
                selectedTextFont={selectedTextFont}
                selectedTextWeight={selectedTextWeight}
                TextFont={TextFont}
                TextWeight={TextWeight}
                textSize={textSize}
                text2Size={text2Size}
                inputPhone={inputPhone}
                inputPoste={inputPoste}
                inputEmail={inputEmail}
                inputAdresse={inputAdresse}
                inputDatNais={inputDatNais}
                inputSituation={inputSituation}
                inputSite={inputSite}
                inputNationalite={inputNationalite}
                emailVisible={emailVisible}
                numVisible={numVisible}
                adresseVisible={adresseVisible}
                datVisible={datVisible}
                natioVisible={natioVisible}
                siteVisible={siteVisible}
                situaVisible={situaVisible}
              />
            )}
            {selectedTemplate === "Modele2" && (
              <Modele2
                bgColor={bgColor}
                bg2Color={bg2Color}
                bg3Color={bg3Color}
                bg4Color={bg4Color}
                imageUrl={imageUrl}
                isPhotoRounded={isPhotoRounded}
                isNameUppercase={isNameUppercase}
                selectedTextFont={selectedTextFont}
                selectedTextWeight={selectedTextWeight}
                TextFont={TextFont}
                TextWeight={TextWeight}
                textSize={textSize}
                text2Size={text2Size}
                inputPhone={inputPhone}
                inputPoste={inputPoste}
                inputEmail={inputEmail}
                inputAdresse={inputAdresse}
                inputDatNais={inputDatNais}
                inputSituation={inputSituation}
                inputSite={inputSite}
                inputNationalite={inputNationalite}
                emailVisible={emailVisible}
                numVisible={numVisible}
                adresseVisible={adresseVisible}
                datVisible={datVisible}
                natioVisible={natioVisible}
                siteVisible={siteVisible}
                situaVisible={situaVisible}
              />
            )}
            {selectedTemplate === "Modele3" && (
              <Modele3
                bgColor={bgColor}
                bg2Color={bg2Color}
                bg3Color={bg3Color}
                bg4Color={bg4Color}
                imageUrl={imageUrl}
                isPhotoRounded={isPhotoRounded}
                isNameUppercase={isNameUppercase}
                selectedTextFont={selectedTextFont}
                selectedTextWeight={selectedTextWeight}
                TextFont={TextFont}
                TextWeight={TextWeight}
                textSize={textSize}
                text2Size={text2Size}
                inputPhone={inputPhone}
                inputPoste={inputPoste}
                inputEmail={inputEmail}
                inputAdresse={inputAdresse}
                inputDatNais={inputDatNais}
                inputSituation={inputSituation}
                inputSite={inputSite}
                inputNationalite={inputNationalite}
                emailVisible={emailVisible}
                numVisible={numVisible}
                adresseVisible={adresseVisible}
                datVisible={datVisible}
                natioVisible={natioVisible}
                siteVisible={siteVisible}
                situaVisible={situaVisible}
              />
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
};


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { login })(Dashboard)
