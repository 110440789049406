import React from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import {Row,col} from "react-bootstrap";
export const Accueil = () => {
  return (
    <div className="App">
      <header id="header" className="fixed-top ">
        <div className="container d-flex align-items-center">
          <h1 className="logo me-auto">
            
              <span className="navbar-brand-text">
                Kapital-<span>CV</span>
              </span>
           
          </h1>
          <div id="nav_bar" className="nav_bar">
            <ul>
              <li>
                <NavLink
                  to="/Inscription"
                  className="getstarted scrollto"
                  style={{ backgroundColor: "#ff6600", color: "#fff"}}
                >
                  Créer mon CV
                </NavLink>
              </li>
              <li>
                <NavLink to="/Connexion" className="getstarted scrollto">
                  Connexion
                </NavLink>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </div>
        </div>
      </header>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>
                Créez votre CV professionnel en ligne rapidement avec la
                plateforme Kapital-CV.
              </h1>
              <h2 style={{ color: "black" }}>
                En quelques minutes, créez rapidement votre CV et votre lettre
                de motivation, choisissez un modèle, personnalisez son design,
                puis téléchargez-le en PDF
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <NavLink to="/Connexion" className="btn-get-started scrollto">
                  Créer mon CV
                </NavLink>
                <span className="backdrop"></span>
              </div>
            </div>
            <div
              className="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img src="assets/img/cv3.png" className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      <div id="services" className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div
                className="section-heading  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <h4>Fonctionnement de Kapital-CV</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4" >
              <div className="service-item first-service">
                <div className="icon">1</div>
                <p>Créer moi-même mon CV </p>
                <h4 className="explication">
                  En concevant toi-même ton CV, tu peux mettre en avant de manière unique tes atouts 
                  professionnels et exprimer ta personnalité. Cette approche personnalisée renforce ta présence 
                  professionnelle et te donne une meilleure maîtrise de la présentation de ton parcoursaux employeurs potentiels.
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-item second-service">
                <div className="icon">2</div>
                <p>Personnaliser le CV </p>
                <h4 className="explication">
                En ajustant le contenu, la mise en page, vous créez un document qui reflète fidèlement
                votre identité professionnelle. Cette personnalisation permet non seulement de répondre aux attentes spécifiques 
                des employeurs, mais aussi de mettre en avant de manière distinctive vos compétences et réalisations.
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-item third-service">
                <div className="icon">3</div>
                <p>
                  Télecharger mon CV 
                </p>
                <h4 className="explication">
                En téléchargeant ton CV, tu peux explorer de manière approfondie ton historique professionnel. Ce document soigneusement préparé offre une perspective détaillée sur tes expériences.
                N'hésitez pas à découvrir les détails de ton parcours en téléchargeant ton CV pour une compréhension complète de ton profil.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="footer">
          <p>
            &copy; 2023 <span className="span2">kapital-CV</span> Tous droits
            réservés.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Accueil();
