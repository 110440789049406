import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./Dashboard.css";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import ModalAdd from "./Modaux/AddExperience";
import Diplome from "./Modaux/AddDiplome";
import Competence from "./Modaux/AddCompetence";
import Langue from "./Modaux/AddLangue";
import Interet from "./Modaux/AddInteret";
import ModalMod from "./Modaux/Modif/ModifierExperience";
import ModDiplome from "./Modaux/Modif/ModifierDiplome";
import ModCompetence from "./Modaux/Modif/ModifierCompetence";
import ModLangue from "./Modaux/Modif/ModifierLangue";
import ModInteret from "./Modaux/Modif/ModifierInteret";
import axios from "axios";
import { connect } from "react-redux";
import { load_user } from "../../actions/auth";
import { deleteexperience, deletediplome, deletecompetence, deletecentreinteret, deletelangue } from "./ApiService";
import Swal from "sweetalert2";
import ModalConfirmation from "./ModalConfirmation";
const Modele1 = ({ downloadPDF, bgColor, bg2Color, bg3Color, bg4Color,
  imageUrl, isPhotoRounded, isNameUppercase,
  selectedTextFont, selectedTextWeight,
  TextFont, TextWeight, textSize, text2Size, inputPhone, inputPoste, inputEmail, inputAdresse, inputDatNais,
  inputSituation, inputSite, inputNationalite, emailVisible, numVisible,adresseVisible, adresVisible, user, load_user,
  datVisible, natioVisible, siteVisible, situaVisible
}) => {
  const [showButtons, setShowButtons] = useState(false);
  const [showButtons1, setShowButtons1] = useState(false);
  const [showButtons2, setShowButtons2] = useState(false);
  const [showButtons3, setShowButtons3] = useState(false);
  const [showButtons4, setShowButtons4] = useState(false);
  const [showButtons5, setShowButtons5] = useState(false);
  const [showButtons6, setShowButtons6] = useState(false);
  const [showButtons7, setShowButtons7] = useState(false);
  const [showButtons8, setShowButtons8] = useState(false);
  const [showButtons9, setShowButtons9] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddModal1, setShowAddModal1] = useState(false);
  const [showAddModal2, setShowAddModal2] = useState(false);
  const [showAddModal3, setShowAddModal3] = useState(false);
  const [showAddModal4, setShowAddModal4] = useState(false);
  const [showAddModal5, setShowAddModal5] = useState(false);
  const [showAddModal6, setShowAddModal6] = useState(false);
  const [showAddModal7, setShowAddModal7] = useState(false);
  const [showAddModal8, setShowAddModal8] = useState(false);
  const [showAddModal9, setShowAddModal9] = useState(false);
  const [expe, setExpe] = useState()
  const handleAddClick = () => {
    setShowAddModal(true);
  };
  const handleAddClick1 = () => {
    setShowAddModal1(true);
  };
  const AddDiplome = () => {
    setShowAddModal2(true);
  };
  const ModifDiplome = () => {
    setShowAddModal3(true);
  };
  const AddCompetence = () => {
    setShowAddModal4(true);
  };
  const ModifCompetence = () => {
    setShowAddModal5(true);
  };
  const AddInteret = () => {
    setShowAddModal6(true);
  };
  const ModifInteret = () => {
    setShowAddModal7(true);
  };
  const AddLangue = () => {
    setShowAddModal8(true);
  };
  const ModifLangue = () => {
    setShowAddModal9(true);
  };
  const handleCloseModals = () => {
    setShowAddModal(false);
    setShowAddModal1(false);
    setShowAddModal2(false);
    setShowAddModal3(false);
    setShowAddModal4(false);
    setShowAddModal5(false);
    setShowAddModal6(false);
    setShowAddModal7(false);
    setShowAddModal8(false);
    setShowAddModal9(false);
  };
  const [experiences, setExperiences] = useState([]);
  const [diplomas, setDiplomas] = useState([]);
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);

  //Fonction qui permet de gérer le glisser-déposer au niveau des blocs d'expérience et de diplôme
  const onDragEnd = (result) => {
    const { source, destination } = result;
    // Si la destination est nulle, l'élément a été déplacé en dehors de la liste
    if (!destination) {
      return;
    }
    // Copiez les tableaux pour ne pas modifier l'état d'origine directement
    const newExperiences = [...experiences];
    const newDiplomas = [...diplomas];
    // Gérer la réorganisation pour les cartes d'expérience
    if (
      source.droppableId === "experiences" &&
      destination.droppableId === "experiences"
    ) {
      const [removed] = newExperiences.splice(source.index, 1);
      newExperiences.splice(destination.index, 0, removed);
    }
    // Gérer la réorganisation pour les cartes de diplôme
    if (
      source.droppableId === "diplomas" &&
      destination.droppableId === "diplomas"
    ) {
      const [removed] = newDiplomas.splice(source.index, 1);
      newDiplomas.splice(destination.index, 0, removed);
    }
     // Mettre à jour les états avec les nouvelles positions
     setExperiences(newExperiences);
     setDiplomas(newDiplomas);

     //Mettre à jour les positions dans la base de données
     newExperiences.map((experience, index) => (
      //formData = {position: index},
      axios.patch(`${process.env.REACT_APP_API_URL}/sbExpPro/${experience.id}/`, {position: index})
     ));
     
     //Mettre à jour les positions dans la base de données
     newDiplomas.map((diploma, index) => (
      //formData = {position: index},
      axios.patch(`${process.env.REACT_APP_API_URL}/sbDipForm/${diploma.id}/`, {position: index})
     ));
  };

  //Fonction qui permet de gérer le glisser-déposer au niveau du bloc de compétence
  const onSkillsDragEnd = (result) => {
    const { source, destination } = result;

    // Si la destination est nulle, l'élément a été déplacé en dehors de la liste
    if (!destination) {
      return;
    }
    // Copiez les tableaux pour ne pas modifier l'état d'origine directement
    const newSkills = [...skills];
    const movedSkill = newSkills.splice(source.index, 1)[0];
    newSkills.splice(destination.index, 0, movedSkill);
    setSkills(newSkills);
    // Gérer la réorganisation pour les cartes d'expérience
    if (
      source.droppableId === "skills" &&
      destination.droppableId === "skills"
    ) {
      const [removed] = newSkills.splice(source.index, 1);
      newSkills.splice(destination.index, 0, removed);
    }
    // Mettre à jour les états avec les nouvelles positions
    setSkills(newSkills);
    //Mettre à jour les positions dans la base de données
    newSkills.map((skill, index) => (
      //formData = {position: index},
      axios.patch(`${process.env.REACT_APP_API_URL}/sbCompetence/${skill.id}/`, {position: index})
     ));

  };
  //Fonction qui permet de gérer le glisser-déposer au niveau du bloc du centre d'intérêt
  const onInterestsDragEnd = (result) => {
    const { source, destination } = result;
    // Si la destination est nulle, l'élément a été déplacé en dehors de la liste
    if (!destination) {
      return;
    }
    // Copiez les tableaux pour ne pas modifier l'état d'origine directement
    const newInterests = [...interests];
    const movedInterest = newInterests.splice(source.index, 1)[0];
    newInterests.splice(destination.index, 0, movedInterest);

    setInterests(newInterests);
    // Gérer la réorganisation pour les cartes d'expérience
    if (
      source.droppableId === "Interests" &&
      destination.droppableId === "Interests"
    ) {
      const [removed] = newInterests.splice(source.index, 1);
      newInterests.splice(destination.index, 0, removed);
    }
    // Mettre à jour les états avec les nouvelles positions
    setInterests(newInterests);
   //Mettre à jour les positions dans la base de données
   newInterests.map((interest, index) => (
    //formData = {position: index},
    axios.patch(`${process.env.REACT_APP_API_URL}/sbCentreInteret/${interest.id}/`, {position: index})
   ));

  };
  //Fonction qui permet de gérer le glisser-déposer au niveau du bloc de langue
  const onLanguagesDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newLanguages = Array.from(languages);
    const [reorderedLanguage] = newLanguages.splice(result.source.index, 1);
    newLanguages.splice(result.destination.index, 0, reorderedLanguage);
    setLanguages(newLanguages);
    //Mettre à jour les positions dans la base de données
    newLanguages.map((language, index) => (
      //formData = {position: index},
      axios.patch(`${process.env.REACT_APP_API_URL}/langue/${language.id}/`, {position: index})
     ));
  };
  const cvStyles = {
    backgroundColor: bgColor,
    height: "29,7cm",
    fontFamily: selectedTextFont,
    fontWeight: selectedTextWeight,
    overflowY: "hidden",
  };
  const [cv, setCv] = useState([]);
  const [userInfo, setUserInfo] = useState({});

  const UserId = localStorage.getItem('userId');
  const cvId = localStorage.getItem('cvId');
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  useEffect(() => {
    if (cvId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setCv(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [cvId]);


  useEffect(() => {
    // Effectuez une requête GET pour récupérer les données des expériences
         
         axios.get(`${process.env.REACT_APP_API_URL}/sbExpPro/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setExperiences(response.data);

      })
      .catch(error => {
        console.error('Erreur lors de la récupération des expériences:', error);
      });
    

    // Effectuez une requête GET pour récupérer les données des diplômes
    axios.get(`${process.env.REACT_APP_API_URL}/sbDipForm/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setDiplomas(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des diplômes:', error);
      });

    // Effectuez une requête GET pour récupérer les données des compétences
    axios.get(`${process.env.REACT_APP_API_URL}/sbCompetence/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setSkills(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des compétences:', error);
      });

    // Effectuez une requête GET pour récupérer les données des centres d'intérêt
    axios.get(`${process.env.REACT_APP_API_URL}/sbCentreInteret/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setInterests(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des centres d\'intérêt:', error);
      });
    // Effectuez une requête GET pour récupérer les données des langues
    axios.get(`${process.env.REACT_APP_API_URL}/langue/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setLanguages(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des centres d\'intérêt:', error);
      });
  }, []);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmation2, setShowConfirmation2] = useState(false);
  const [showConfirmation3, setShowConfirmation3] = useState(false);
  const [showConfirmation4, setShowConfirmation4] = useState(false);
  const [showConfirmation5, setShowConfirmation5] = useState(false);
  const [selectedDiplomaId, setSelectedDiplomaId] = useState(null);
  const [selectedExperienceId, setSelectedExperienceId] = useState(null);
  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedLangueId, setSelectedLangueId] = useState(null);
  const [selectedInteretId, setSelectedInteretId] = useState(null);

  const handleDeleteBtn = (id) => {
    setSelectedExperienceId(id);
    setShowConfirmation(true);
  };
  const handleDeleteBtn2 = (id) => {
    setSelectedDiplomaId(id);
    setShowConfirmation2(true);
  };
  const handleDeleteBtn3 = (id) => {
    setSelectedSkillId(id);
    setShowConfirmation3(true);
  };
  const handleDeleteBtn4 = (id) => {
    setSelectedInteretId(id);
    setShowConfirmation4(true);
  };
  const handleDeleteBtn5 = (id) => {
    setSelectedLangueId(id);
    setShowConfirmation5(true);
  };
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    setShowConfirmation2(false);
    setShowConfirmation3(false);
    setShowConfirmation4(false);
    setShowConfirmation5(false);
  };
  const handleConfirmationYes = () => {
    deletediplome(selectedDiplomaId)
      .then(res => {
        setDiplomas(diplomas.filter(diploma => diploma.id !== selectedDiplomaId));
        setShowConfirmation2(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du diplôme : ', error);
        setShowConfirmation2(false);
      });
  };
  const handleConfirmationYes2 = () => {
    deleteexperience(selectedExperienceId)
      .then(res => {
        setExperiences(experiences.filter(experience => experience.id !== selectedExperienceId));
        setShowConfirmation(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de l\' expérience : ', error);
        setShowConfirmation(false);
      });
  };
  const handleConfirmationYes3 = () => {
    deletecompetence(selectedSkillId)
      .then(res => {
        setSkills(skills.filter(skill => skill.id !== selectedSkillId));
        setShowConfirmation3(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de compétence: ', error);
        setShowConfirmation3(false);
      });
  };
  const handleConfirmationYes4 = () => {
    deletecentreinteret(selectedInteretId)
      .then(res => {
        setInterests(interests.filter(interest => interest.id !== selectedInteretId));
        setShowConfirmation4(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du centre d\' intérêt : ', error);
        setShowConfirmation4(false);
      });
  };
  const handleConfirmationYes5 = () => {
    deletelangue(selectedLangueId)
      .then(res => {
        setLanguages(languages.filter(language => language.id !== selectedLangueId));
        setShowConfirmation5(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de langue : ', error);
        setShowConfirmation5(false);
      });
  };

  return (
    <Container className="mt-8 template-sheet" style={cvStyles}>
      <span id="modele" style={{ display: "none" }}>
        Modele1
      </span>
      <Row className="" style={{  fontSize: `${textSize}px`, }}>
        <Col style={{ fontSize: `${textSize}px`, }}>
          <div className="" style={{ marginTop: "8px", fontSize: `${textSize}px` }}>
            <div className="custom-card-body">
              <div className="profile-photo2">
                {imageUrl && (
                  <img
                    src={imageUrl}
                    className={`profile-photo2 ${isPhotoRounded ? "rounded" : ""
                      }`}
                    alt=""
                  />
                )}
              </div>
              <h2
                className={`nom changeable-text ${isNameUppercase ? "uppercase" : ""
                  }`}
                style={{ fontSize: `${text2Size}px`, color: bg2Color }}
              >
                {userInfo.nom} {userInfo.prenoms}
              </h2>
              <h3 className="subtitle" style={{ color: bg2Color}}><span id="textform">{inputPoste}</span></h3>
              <p className="in text-center text-black align-items-center" >
                {emailVisible && (
                  <div>
                    <span id="textform">{inputEmail}</span>
                  </div>
                )}
                {numVisible && (
                  <div>
                    <span id="textform">{inputPhone}</span>
                  </div>
                )}
                {adresseVisible && (
                  <div>
                    <span id="textform">{inputAdresse}</span>
                  </div>
                )}
                {datVisible && (
                  <div>
                    <span id="textform">{inputDatNais}</span>
                  </div>
                )}

                {natioVisible && (
                  <div>
                    <span id="textform">{inputNationalite}</span>
                  </div>)}
                {siteVisible && (
                  <div>
                    <span id="textform">{inputSite}</span>
                  </div>
                )}
                {situaVisible && (
                  <div>
                    <span id="textform">{inputSituation}</span>
                  </div>
                )}
              </p>

            </div>
          </div>
        </Col>
      </Row>

      <Row className="" style={{ fontSize: `${textSize}px`, }}>
        <Col
          className="column-left-content bloc"
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
        >
          <div className="d-flex  justify-content-between align-items-center">
            <h2
              className={`text-font-${selectedTextFont}`}
              data-tip="Faites glisser et déposez pour réorganiser les expériences"
              data-for="experiences-tooltip"
              style={{ color: bg3Color, fontFamily: TextFont, fontWeight: TextWeight }}
            >
              Expériences
            </h2>
            {showButtons && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button className="btn btn-link p-1" onClick={handleAddClick}>
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem", color: "#000" }}
                    ></span>
                  </button>
                </OverlayTrigger>

              </div>
            )}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="experiences" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {experiences.map((experience, index) => (
                    <Draggable
                      key={experience.id}
                      draggableId={experience.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div
                            className=""
                            style={{ padding: "13px" }}
                            onMouseEnter={() => setShowButtons1(true)}
                            onMouseLeave={() => setShowButtons1(false)}
                          >
                            <div className="sous-bloc">
                              <div className="d-flex justify-content-between align-items-center">
                                <Card.Title style={{
                                  color: bg4Color,

                                }}>{experience.poste}</Card.Title>
                                {showButtons1 && (
                                  <div className="d-flex">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Modifier</Tooltip>}
                                    >
                                      <button
                                        className="btn btn-link p-1"
                                        onClick={() => {
                                          localStorage.setItem("experience_id", experience.id);
                                          handleAddClick1();
                                        }}
                                      >
                                        <span
                                          className="oi oi-eyedropper"
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#333",
                                          }}
                                        ></span>
                                      </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Supprimer</Tooltip>}
                                    >
                                      <button
                                        className="btn btn-link p-1"
                                        onClick={() => {
                                          handleDeleteBtn(experience.id);
                                        }}
                                      >
                                        <span
                                          className="oi oi-trash"
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#333",
                                          }}
                                        ></span>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                )}
                              </div>

                              <div className="subtitle mb-2 text-muted">
                                {experience.nomEntreprise}
                              </div>
                              <div className="info">
                                {experience.details}
                              </div>
                            </div>
                          </div>
                          <Modal
                            show={showAddModal}
                            onHide={handleCloseModals}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Ajouter</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <ModalAdd />
                            </Modal.Body>

                          </Modal>
                          <Modal
                            show={showAddModal1}
                            onHide={handleCloseModals}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered

                          >
                            <Modal.Header closeButton >
                              <Modal.Title>Modifier</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <ModalMod />
                            </Modal.Body>

                          </Modal>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <Modal show={showConfirmation} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation de suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Êtes-vous sûr de vouloir supprimer cette expérience ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleConfirmationClose}>
                        Non
                      </Button>
                      <Button variant="danger" onClick={handleConfirmationYes2}>
                        Oui
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <Row>
        <Col
          className="bloc"
          onMouseEnter={() => setShowButtons2(true)}
          onMouseLeave={() => setShowButtons2(false)}
        >
          <div className="d-flex  justify-content-between align-items-center">
            <h2 style={{ color: bg3Color, fontFamily: TextFont, fontWeight: TextWeight }}>Diplômes/Formations</h2>
            {showButtons2 && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button className="btn btn-link p-1" onClick={AddDiplome}>
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem", color: "#000" }}
                    ></span>
                  </button>
                </OverlayTrigger>

              </div>
            )}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="diplomas" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {diplomas.map((diploma, index) => (
                    <Draggable
                      key={diploma.id}
                      draggableId={diploma.id.toString()}
                      index={index}
                    >

                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div
                            className=""
                            style={{ padding: "10px" }}
                            onMouseEnter={() => setShowButtons3(true)}
                            onMouseLeave={() => setShowButtons3(false)}
                          >
                            <div className="sous-bloc">
                              <div className="d-flex justify-content-between align-items-center">
                                <Card.Title style={{
                                  color: bg4Color,
                                }}>{diploma.nomSousDipForm}</Card.Title>
                                {showButtons3 && (
                                  <div className="d-flex">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Modifier</Tooltip>}
                                    >
                                      <button
                                        className="btn btn-link p-1"
                                        onClick={() => {
                                          localStorage.setItem("diplome_id", diploma.id);
                                          ModifDiplome();
                                        }}
                                      >
                                        <span
                                          className="oi oi-eyedropper"
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#333",
                                          }}
                                        ></span>
                                      </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Supprimer</Tooltip>}
                                    >
                                      <button
                                        className="btn btn-link p-1"
                                        onClick={() => {
                                          handleDeleteBtn2(diploma.id);
                                        }}
                                      >

                                        <span
                                          className="oi oi-trash"
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#333",
                                          }}
                                        ></span>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                )}
                              </div>
                              <div className="subtitle mb-2 text-muted">
                                {diploma.nomEcole}
                              </div>

                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <Modal show={showConfirmation2} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation de suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Êtes-vous sûr de vouloir supprimer ce diplôme ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleConfirmationClose}>
                        Non
                      </Button>
                      <Button variant="danger" onClick={handleConfirmationYes}>
                        Oui
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <Row className="" style={{ fontSize: `${textSize}px`, }}>
        <Col
          className=" bloc"
          onMouseEnter={() => setShowButtons4(true)}
          onMouseLeave={() => setShowButtons4(false)}
        >
          <div className="d-flex  justify-content-between align-items-center">
            <h2 style={{ color: bg3Color, fontFamily: TextFont, fontWeight: TextWeight }}>Compétences</h2>
            {showButtons4 && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button className="btn btn-link p-1" onClick={AddCompetence}>
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem", color: "#000" }}
                    ></span>
                  </button>
                </OverlayTrigger>

              </div>
            )}
          </div>

          <div>
            {skills.map(skill => (
              <div className="info">
                <div
                  onMouseEnter={() => setShowButtons5(true)}
                  onMouseLeave={() => setShowButtons5(false)}
                >
                  <div className="sous-bloc2">
                    <div className="d-flex justify-content-between align-items-center">
                      <span style={{
                        color: bg4Color,

                      }}>{skill.competence}  {skill.niveauAtout}%</span>
                      {showButtons5 && (
                        <div className="d-flex">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Modifier</Tooltip>}
                          >
                            <button
                              className="btn btn-link p-1"
                              onClick={() => {
                                localStorage.setItem('Competence_id', skill.id);
                                ModifCompetence();
                              }}
                            >
                              <span
                                className="oi oi-eyedropper"
                                style={{
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                              ></span>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>Supprimer</Tooltip>}
                          >
                            <button
                              className="btn btn-link p-1"
                              onClick={() => {
                                handleDeleteBtn3(skill.id);
                              }}
                            >
                              <span
                                className="oi oi-trash"
                                style={{
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                              ></span>
                            </button>
                          </OverlayTrigger>
                        </div>
                      )}
                    </div>



                  </div>
                </div>
              </div>

            ))}
            <Modal show={showConfirmation3} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation de suppression</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Êtes-vous sûr de vouloir supprimer cette compétence ?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmationClose}>
                  Non
                </Button>
                <Button variant="danger" onClick={handleConfirmationYes3}>
                  Oui
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

        </Col>
        <Col
          className=" bloc"
          onMouseEnter={() => setShowButtons6(true)}
          onMouseLeave={() => setShowButtons6(false)}
        >
          <div className="d-flex  justify-content-between align-items-center">
            <h2 style={{ color: bg3Color, fontFamily: TextFont, fontWeight: TextWeight }}>Centres d'intérêt</h2>
            {showButtons6 && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button className="btn btn-link p-1" onClick={AddInteret}>
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem", color: "#000" }}
                    ></span>
                  </button>
                </OverlayTrigger>
                {/* <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Supprimer</Tooltip>}
                >
                  <button
                    className="btn btn-link p-1"
                    onClick={()=>{handleDeleteBtn(experience.id);
                                        }}
                  >
                    <span
                      className="oi oi-trash"
                      style={{ fontSize: "1rem", color: "#000" }}
                    ></span>
                  </button>
                </OverlayTrigger> */}
              </div>
            )}
          </div>
          <DragDropContext onDragEnd={onInterestsDragEnd}>
            <Droppable droppableId="interests" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <ul className="info">
                    {interests.map((interest, index) => (
                      <Draggable
                        key={interest.id}
                        draggableId={interest.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <div
                              onMouseEnter={() => setShowButtons7(true)}
                              onMouseLeave={() => setShowButtons7(false)}
                            >
                              <div className="sous-bloc">
                                <div className="d-flex  justify-content-between align-items-center" style={{
                                  color: bg4Color,

                                }}>
                                  {interest.interet}
                                  {showButtons7 && (
                                    <div className="d-flex">
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>Modifier</Tooltip>}
                                      >
                                        <button
                                          className="btn btn-link p-1"
                                          onClick={() => {
                                            localStorage.setItem('Interêt_id', interest.id);
                                            ModifInteret();
                                          }}

                                        >
                                          <span
                                            className="oi oi-eyedropper"
                                            style={{
                                              fontSize: "0.9rem",
                                              color: "#333",
                                            }}
                                          ></span>
                                        </button>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>Supprimer</Tooltip>}
                                      >
                                        <button
                                          className="btn btn-link p-1"
                                          onClick={() => {
                                            handleDeleteBtn4(interest.id);
                                          }}
                                        >
                                          <span
                                            className="oi oi-trash"
                                            style={{
                                              fontSize: "0.9rem",
                                              color: "#333",
                                            }}
                                          ></span>
                                        </button>
                                      </OverlayTrigger>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    <Modal show={showConfirmation4} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                      centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirmation de suppression</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Êtes-vous sûr de vouloir supprimer ce centre d'intérêt ?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleConfirmationClose}>
                          Non
                        </Button>
                        <Button variant="danger" onClick={handleConfirmationYes4}>
                          Oui
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </ul>

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <Row className="" style={{ fontSize: `${textSize}px`, }}>
        <Col
          className=" bloc"
          onMouseEnter={() => setShowButtons8(true)}
          onMouseLeave={() => setShowButtons8(false)}
        >
          <div className="d-flex  justify-content-between align-items-center">
            <h2 style={{ color: bg3Color, fontFamily: TextFont, fontWeight: TextWeight }}>Langues</h2>
            {showButtons8 && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button className="btn btn-link p-1" onClick={AddLangue}>
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem", color: "#000" }}
                    ></span>
                  </button>
                </OverlayTrigger>

              </div>
            )}
          </div>

          <DragDropContext onDragEnd={onLanguagesDragEnd}>
            <Droppable droppableId="languages" direction="vertical">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="info"
                >
                  {languages.map((language, index) => (
                    <Draggable
                      key={language.id}
                      draggableId={language.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Col
                            onMouseEnter={() => setShowButtons9(true)}
                            onMouseLeave={() => setShowButtons9(false)}
                          >
                            <div className="sous-bloc1">
                              <div className="d-flex  justify-content-between align-items-center">
                                <span style={{
                                  color: bg4Color,

                                }}>{language.nom} {language.barreCompetence} %</span>
                                {showButtons9 && (
                                  <div className="d-flex">
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Modifier</Tooltip>}
                                    >
                                      <button
                                        className="btn btn-link p-1"
                                        onClick={ModifLangue}
                                      >
                                        <span
                                          className="oi oi-eyedropper"
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#333",
                                          }}
                                        ></span>
                                      </button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Supprimer</Tooltip>}
                                    >
                                      <button
                                        className="btn btn-link p-1"
                                        onClick={() => {
                                          handleDeleteBtn5(language.id);
                                        }}
                                      >
                                        <span
                                          className="oi oi-trash"
                                          style={{
                                            fontSize: "0.9rem",
                                            color: "#333",
                                          }}
                                        ></span>
                                      </button>
                                    </OverlayTrigger>
                                  </div>
                                )}
                              </div>

                            </div>
                          </Col>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <Modal show={showConfirmation5} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation de suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Êtes-vous sûr de vouloir supprimer cette langue ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleConfirmationClose}>
                        Non
                      </Button>
                      <Button variant="danger" onClick={handleConfirmationYes5}>
                        Oui
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <Modal
        show={showAddModal2}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Diplome handleClose={handleCloseModals} />
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal4}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Competence handleClose={handleCloseModals} />
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal6}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Interet handleClose={handleCloseModals} />
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal8}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Langue handleClose={handleCloseModals} />
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalAdd handleClose={handleCloseModals} />
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal1}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalMod handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal3}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModDiplome handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal5}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModCompetence handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal7}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModInteret handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal9}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModLangue handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
    </Container>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { load_user })(Modele1);
