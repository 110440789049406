import React from "react";
import "./Navbar.css";
import { Navbar, Nav,Button } from "react-bootstrap";
import { MdAccountCircle } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { logout } from '../../actions/auth';
import { PDFDocument } from 'pdf-lib';

const Navbars = ({ logout, isAuthenticated }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const downloadCompressedPDF = async () => {
    const capture = document.querySelector(".template-sheet");
  
    // Utiliser html2canvas pour capturer l'image
    const canvas = await html2canvas(capture, { scale: 2, useCORS: true });
    const imgData = canvas.toDataURL("image/png");
  
    // Créer un nouveau document PDF avec pdf-lib
    const pdfDoc = await PDFDocument.create();
    
    // Créer une nouvelle page en format A4 en orientation paysage
    const page = pdfDoc.addPage([495, 542]); // Format A4 en millimètres (210 x 297) en orientation paysage
  
    // Ajouter l'image capturée à la page PDF
    const pngImage = await pdfDoc.embedPng(imgData);
    const { width, height } = page.getSize();
    page.drawImage(pngImage, { x: 0, y: 0, width, height });
  
    // Compression du PDF
    // Notez que pdf-lib n'a pas directement de fonction pour supprimer les images,
    // vous pouvez ajuster la méthode de compression en fonction de vos besoins
  
    // Télécharger le fichier PDF compressé
    const compressedArrayBuffer = await pdfDoc.save();
  
    // Créer un objet Blob et générer un lien de téléchargement
    const blob = new Blob([compressedArrayBuffer], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
  
    // Créer un lien invisible et le déclencher pour le téléchargement
    const a = document.createElement("a");
    a.href = url;
    a.download = "resume.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  
    // Libérer les ressources
    URL.revokeObjectURL(url);
  
    Toast.fire({
      title: "Téléchargement en cours",
      text: "Veuillez patienter",
      icon: "success",
    });
  };
  
  // Utilisation de la nouvelle fonction pour télécharger le PDF compressé
  const downloadPDF = async () => {
    try {
      await downloadCompressedPDF();
    } catch (error) {
      console.error("Erreur lors de la compression ou du téléchargement du PDF :", error);
      Toast.fire({
        title: "Erreur de téléchargement",
        text: "Une erreur s'est produite lors du téléchargement du PDF.",
        icon: "error",
      });
    }
  };
  return (
    <Navbar expand="lg" fixed="top">
      <Navbar.Brand as={NavLink} to="/tableau">
        <span className="ms-3">
          Kapital<span style={{ color: "#ff6600" }}>CV</span>
        </span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link as={NavLink} to="/tableau/profil" style={{ color: "#fff" }}>
            Mon compte
          </Nav.Link>
          <Nav.Link as={NavLink} to="/" style={{ color: "#fff" }} onClick={() => logout()} >
            Déconnexion
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Button
        style={{ backgroundColor: "#ff6600" }}
        className="border-0 me-3"
        onClick={downloadPDF}
      >
        Télécharger
      </Button>
    </Navbar>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(mapStateToProps, { logout })(Navbars);
