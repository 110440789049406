import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Connexion from "./components/Connexion/Connexion";
import { Accueil } from "./components/Accueil";
import React from "react";
import Inscription from "./components/Connexion/Inscription";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { Home } from "./components/Home/Home";
import Tableau from "./components/Pages/Tableau_bord";
import Mescv from "./components/Pages/Mes_CV/Mes_cv";
import Meslettres from "./components/Pages/Mes_Lettres/Mes_lettres";
import Motivation from "./components/Lettre_motivation/Lettre_motivation";
import Monprofil from "./components/Pages/Mon_profil";
import { Cv } from "./Cv/cv";
import Mail from "./components/Connexion/Confirmail";
import ForgetPassword from "./components/Connexion/ForgetPassword";
import ResendActivationEmail from "./components/Connexion/ResendActivationEmail";

import Activate from './containers/Activate';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';

import { Provider } from 'react-redux';
import store from './store';
import Layout from './hocs/Layout';

const App = () => (
  <Provider store={store}>
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/connexion" element={<Connexion />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resend_activation_email" element={<ResendActivationEmail />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/confirm" element={<Mail />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sidebar" element={<Sidebar />} />
          <Route path="/lettre-motivation" element={<Motivation />} />
          <Route path="/home" element={<Home />} />
          <Route path="/tableau" element={<Tableau />} />
          <Route path="/tableau/Mes_CV" element={<Mescv />} />
          <Route path="/tableau/Mes_lettres" element={<Meslettres />} />
          <Route path="/tableau/profil" element={<Monprofil />} />
          <Route path="/cv" element={<Cv />} />
          <Route path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
          <Route path='/activate/:uid/:token' element={<Activate />} />
        </Routes>
      </Layout>
    </Router>
  </Provider>
);

export default App;
