import React, { useState, useRef, useEffect } from "react";
import "./Inscription.css";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import { signup } from '../../actions/auth';

const Inscription = ({ signup, isAuthenticated, accountcreated }) => {
  const [signupForm, setSignupForm] = useState({
    email: "",
    password: "",
    re_password: "",
    nom: "",
    prenoms: "",
    genre: "",
  });
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <span className="motcorrect" style={{ color: isPasswordNotValid ? 'red' : 'green' }}>Votre mot de passe doit contenir au moins 8 caractères, une lettre minuscule, une lettre majuscule, un nombre et un caractère spécial.</span>
      </div>
    </Tooltip>
  );

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [errors, setErrors] = useState({});
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isPasswordNotValid, setIsPasswordNotValid] = useState(true);


  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const isPasswordLengthValid = newPassword.length >= 8; //  Vérifie la longueur minimale qui est de 8
    const isPasswordContainsLowerCaseLetter = /[a-z]/.test(newPassword); //  Vérifie la présence d'au moins une lettre minuscule
    const isPasswordContainsUpperCaseLetter = /[A-Z]/.test(newPassword); // Vérifie la présence d'au moins une lettre majuscule
    const isPasswordContainsNumber = /\d+/.test(newPassword); // Vérifie la présence d'au moins un chiffre
    const isPasswordContainsSpecialChar = /[\!\@\#\$\%\^\&\*\(\)_\+\{\}\[\]\:\;\<\>,\.\?~\\\-\/]/.test(newPassword); // Vérifie la présence d'au moins un caractère spécial

    if (!isPasswordContainsLowerCaseLetter || !isPasswordContainsUpperCaseLetter || !isPasswordLengthValid || !isPasswordContainsNumber || !isPasswordContainsSpecialChar) {
      setIsPasswordNotValid(true);
    } else {
      setIsPasswordNotValid(false);
    }

    // Mettre à jour le mot de passe dans l'état
    setSignupForm({ ...signupForm, password: newPassword });
  }



  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const navigate = useNavigate();
  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validation du champ email 
    if (!signupForm.email.trim()) {
      newErrors.email = "L'email est requis";
    }

    // Validation du champ mot de passe 
    if (!signupForm.password.trim()) {
      newErrors.password = "Le mot de passe est requis";
    }

    //Validation du champ nom
    if (!signupForm.nom.trim()) {
      newErrors.nom = "Le nom est requis";
    }

    //Validation du champ prénoms
    if (!signupForm.prenoms.trim()) {
      newErrors.prenoms = "Le prénom est requis";
    }

    //Validation du champ genre
    if (!signupForm.genre.trim()) {
      newErrors.genre = "Le genre est requis";
    }

    // Mettez à jour l'état des erreurs
    setErrors(newErrors);

    // Si des erreurs sont présentes, n'envoyez pas la demande
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (signupForm.password !== signupForm.re_password) {
      setPasswordMismatch(true);
      Toast.fire({
        title: "Les mots de passe ne correspondent pas.",
        icon: "error",
      });
      return;
    }
    if (isPasswordNotValid) {
      Toast.fire({
        title: "Le mot de passe ne respecte pas les exigences minimales de sécurité.",
        icon: "warning",
      });
      return;
    }

    if (signupForm.password === signupForm.re_password) {
      signup(signupForm.nom, signupForm.prenoms, signupForm.genre, signupForm.email, signupForm.password, signupForm.re_password);
    }

    /* try {
      const response = await axios.post("http://127.0.0.1:8000/register/", {
        email: signupForm.email,
        password: signupForm.password,
        nom: signupForm.nom,
        prenoms: signupForm.prenoms,
        genre: signupForm.genre,
      });

      if (response.data === "Cet utilisateur existe déjà !") {
        // Gérer le cas de l'utilisateur existant ici si nécessaire
        Toast.fire({
          title: "Utilisateur existant",
          text: "Cet utilisateur existe déjà !",
          icon: "warning",
        });
      } else {
        Toast.fire({
          title: "Inscription réussie",
          icon: "success",
        });
        navigate("/connexion");
      }
    } catch (error) {
      Toast.fire({
        title: "Une erreur s'est produite lors de l'inscription",
        icon: "error",
      });
    } */
  };

  if (isAuthenticated) {
    return navigate('/');
  }
  if (accountcreated) {
    navigate('/connexion');
  }
  
  return (
    <div className="container23">
      <div className="screen">
        <div className="screen__content">
          <form className="login" onSubmit={handleSignupSubmit}>
            <div className="login__field">
              <i className="login__icon bi bi-envelope-fill"></i>
              <input
                type="email"
                className="login__input"
                placeholder="Email"
                value={signupForm.email}
                onChange={(e) =>
                  setSignupForm({ ...signupForm, email: e.target.value })
                }
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <Row>
              <Col className="login__field">
                <i className="login__icon bi bi-lock-fill"></i>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <input
                    type="password"
                    className="login__input"
                    placeholder="Password"
                    value={signupForm.password}
                    onChange={handlePasswordChange}
                  />
                </OverlayTrigger>
                {errors.password && <div className="error">{errors.password}</div>}
              </Col>
              <Col className="login__field">
                <i className="login__icon bi bi-lock-fill"></i>
                <input
                  type="password"
                  className="login__input"
                  placeholder="Confirmer Password"
                  value={signupForm.re_password}
                  onChange={(e) =>
                    setSignupForm({
                      ...signupForm,
                      re_password: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col className="login__field">
                <i className="login__icon bi bi-person-fill"></i>
                <input
                  type="text"
                  className="login__input"
                  placeholder="Nom"
                  value={signupForm.nom}
                  onChange={(e) =>
                    setSignupForm({
                      ...signupForm,
                      nom: e.target.value,
                    })
                  }
                />
                {errors.nom && <div className="error">{errors.nom}</div>}
              </Col>
              <Col className="login__field">
                <i className="login__icon bi bi-person-fill"></i>
                <input
                  type="text"
                  className="login__input"
                  placeholder="Prénoms"
                  value={signupForm.prenoms}
                  onChange={(e) =>
                    setSignupForm({
                      ...signupForm,
                      prenoms: e.target.value,
                    })
                  }
                />
                {errors.prenoms && <div className="error">{errors.prenoms}</div>}
              </Col>
            </Row>

            <div className="login__field login__input2 ">
              <FormLabel className="d-flex align-items-center justify-content-center" id="demo-row-radio-buttons-group-label">
                Genre
              </FormLabel>
              <RadioGroup
                className="d-flex align-items-center justify-content-center"
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="genre"
                value={signupForm.genre}
                onChange={(e) =>
                  setSignupForm({
                    ...signupForm,
                    genre: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="Homme"
                  control={<Radio id="homme" />}
                  label="Homme"
                />
                <FormControlLabel
                  value="Femme"
                  control={<Radio id="femme" />}
                  label="Femme"
                />
              </RadioGroup>
              {errors.genre && <div className="error">{errors.genre}</div>}
            </div>


            <NavLink
              className="button login__submit"
              onClick={handleSignupSubmit}
            >
              <span className="button__text ">S'inscrire</span>
              <i className="button__icon bi bi-chevron-right"></i>
            </NavLink>
            <p className="lien">
              Vous avez déjà un compte ?
              <NavLink className="Con" to="/connexion">
                Se connecter
              </NavLink>
            </p>
          </form>
        </div>
        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>
          <span className="screen__background__shape screen__background__shape3"></span>
          <span className="screen__background__shape screen__background__shape2"></span>
          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  accountcreated: state.auth.accountcreated
});

export default connect(mapStateToProps, { signup })(Inscription);
