import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
//import { useParams } from "react-router-dom";
import axios from "axios";

export const Home = () => {
  //const { userEmail } = useParams();
  const [formData, setFormData] = useState({
    nom: "",
    prenoms: "",
    genre: "",
  });
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/`, {
        email: signupForm.email, // Utilisation de l'adresse e-mail de l'URL
        data: formData,
      });
      alert("succès");
      // Traiter la réponse ici en fonction de vos besoins
    } catch (error) {
      // Traiter les erreurs ici
      alert("erreur");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Box sx={{ bgcolor: "#dfe7ee" }} style={{ padding: "30px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FloatingLabel
                controlId="floatingInput"
                label="Nom"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="nom"
                  name="nom"
                  value={formData.nom}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Grid>
            <Grid item xs={8}>
              <FloatingLabel
                controlId="floatingInput"
                label="Prénom"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="prenom"
                  name="prenoms"
                  value={formData.prenoms}
                  onChange={handleInputChange}
                />
              </FloatingLabel>
            </Grid>
          </Grid>
          <FormLabel id="demo-row-radio-buttons-group-label">Genre</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="genre"
            value={formData.genre}
            onChange={handleInputChange}
          >
            <FormControlLabel
              value="Homme"
              control={<Radio id="homme" />}
              label="Homme"
            />
            <FormControlLabel
              value="Femme"
              control={<Radio id="femme" />}
              label="Femme"
            />
          </RadioGroup>
          {/* <Grid item xs={8}>
            <FloatingLabel
              controlId="floatingInput"
              label="Poste"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Précisez le poste recherché"
                id="poste"
              />
            </FloatingLabel>
          </Grid>
          <Grid item xs={8}>
            <FloatingLabel
              controlId="floatingInput"
              label="Prénom"
              className="mb-3"
            >
              <Form.Select aria-label="Default select example" id="secteur">
                <option>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </FloatingLabel>
          </Grid> */}

          <div className="d-flex justify-content-end">
            <Button
              style={{ backgroundColor: "#264a67", color: "#fff" }}
              onClick={handleFormSubmit}
            >
              Next
            </Button>
          </div>
        </Box>
      </Box>
    </Container>
  );
};
export default Home;
