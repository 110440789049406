import React, { useState } from "react";
import styled from "styled-components";
import { MdAccountCircle } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { Form, Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from '../../../actions/auth';
export const Navs3 = ({ logout, isAuthenticated,props }) => {
  const [show2, setShow2] = useState(false);
  const Handleshow2 = () => setShow2(true);
  const Handleclose2 = () => setShow2(false);
  const [validate, setValidate] = useState(false);

  const handleSubmit2 = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidate(true);
  };
  return (
    <Nav>
      <div className="title">
        <h4>
          Mes <strong style={{ color: "#264a67" }}>Lettres</strong>
        </h4>
        <Form.Control
          type="text"
          placeholder="Rechercher une de mes lettres"
          size="lg"
          style={{ width: "250%" }}
        />
      </div>
      <div className="search">
        {/* <Button className="but" onClick={Handleshow2}>
          Créer une lettre
        </Button> */}
        <NavLink to="/tableau/profil" style={{ color: "#000" }}>
          <MdAccountCircle style={{ fontSize: "40px" }} />
        </NavLink>
        <NavLink style={{ color: "#000" }} to="/">
          <CiLogout style={{ fontSize: "40px" }} onClick={() => logout()}/>
        </NavLink>
      </div>
      <Modal
        show={show2}
        onHide={Handleclose2}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Création de Lettre
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validate} onSubmit={handleSubmit2}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom de votre Lettre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nom de lettre"
                autoFocus
                required
              />
              <Form.Control.Feedback>Validé</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Poste recherché</Form.Label>
              <Form.Control
                type="text"
                placeholder="Poste recherché"
                autoFocus
                required
              />
              <Form.Control.Feedback>Validé</Form.Control.Feedback>
            </Form.Group>

            <Button
              type="submit"
              style={{
                backgroundColor: "#264a67",
                color: "#fff",
                border: "none",
                marginTop: "30px",
                marginLeft: "13O%",
              }}
            >
              Créer le document
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Nav>
  );
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  color: #000;
  font-size: 20px;
  .title {
    margin-left: -5rem;
    h1 {
      font-size: 20px;
      span {
        margin-left: 0.5rem;
        color: #ff6600;
        letter-spacing: 0.2rem;
      }
    }
  }
  .search {
    .but {
      background-color: #264a67;
      color: #fff;
      border: none;
      margin-right: 10px;
      opacity: 0.7;
      &:hover {
        opacity: 2;
      }
    }
    .butt {
      background-color: #fff;
      color: #264a67;
      border: none;
      box-shadow: 0 0 5px 0 #000;
      margin-right: 10px;
      opacity: 0.7;
      &:hover {
        opacity: 2;
      }
    }
  }
`;
export default connect(mapStateToProps, { logout })(Navs3);
