import React from "react";
import { Form } from "react-bootstrap";

export const Personnalisation = ({onBgColorChange,
    onBg2ColorChange,onMajuscule,handleFontChange,
    handleWeightChange,
    FontChange,
    WeightChange,onTextSizeChange,
    TextSizeChange}) => {
    return (
        <div>
            <Form>
                <h4>Mise en page</h4>
                <hr />
                <div className="cvoption-container row pt-3">
                    <div className="col-md-6">
                        <label for="bg1colorlabel" className="col-form-label">Couleur primaire</label>
                    </div>
                    <div className="col-md-6">
                        <input type="color" id="bgColor" className="form-control form-control-color" title="Choisir la couleur" onChange={(event) =>
                                onBgColorChange(event.target.value)
                              }/>
                    </div>
                </div>
                <div className="cvoption-container row pt-3">
                    <div className="col-md-6">
                        <label for="bg1colorlabel" className="col-form-label">Couleur secondaire</label>
                    </div>
                    <div className="col-md-6">
                        <input type="color" id="bgColor" className="form-control form-control-color" title="Choisir la couleur" onChange={(event) =>
                                onBg2ColorChange(event.target.value)
                              }/>
                    </div>
                </div>
                <div className="cvoption-container row g-3 pt-2">
                    <div className="col-md-6">
                        <label for="namecolorlabel" className="col-form-label">Nom majuscule</label>
                    </div>
                    <div className="col-md-6 pt-2">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input cv-name-upper" type="radio" name="cv-name-upper" value="oui" onChange={() => onMajuscule(true)}/>
                            <label className="form-check-label">Oui</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input cv-name-upper" type="radio" name="cv-name-upper" value="non" onChange={() => onMajuscule(false)}/>
                            <label className="form-check-label">Non</label>
                        </div>
                    </div>
                </div>
                <div className="cvoption-container row pt-3">
                    <div className="col-md-6">
                        <label for="textfontlabel" className="col-form-label">Police des textes</label>
                    </div>
                    <div className="col-md-6">
                        <select id="textfont" className="form-select form-select-sm" onChange={(e) => handleFontChange(e.target.value)}>
                            <option style={{ fontFamily: "Arial" }}value="Arial">Arial</option>
                            <option style={{ fontFamily: "Verdana" }}value="Verdana">Verdana</option>
                            <option style={{ fontFamily: "Cursive" }}value="cursive">cursive</option>
                        </select>
                        <select id="textweight" className="form-select form-select-sm" onChange={(e) => handleWeightChange(e.target.value)}>
                            <option className="fw-bold" value="bolder">Très gras</option>
                            <option className="fw-normal" value="normal"style={{ fontWeight: "normal" }}selected>Normal</option>
                        </select>
                    </div>
                </div>
                <div className="cvoption-container row pt-3">
                    <div className="col-md-6">
                        <label for="textsizelabel" className="col-form-label">Taille des textes</label>
                    </div>
                    <div className="range col-md-6 pt-2">
                        <input id="textsize" type="range" className="form-range" onChange={(event) => onTextSizeChange(parseInt(event.target.value,10))}/>
                    </div>
                </div>
               <div className="cvoption-container row pt-3">
                    <div className="col-md-6">
                        <label for="titlefontlabel" className="col-form-label">Police des titres</label>
                    </div>
                    <div className="col-md-6">
                        <select id="textfont" className="form-select form-select-sm" onChange={(e) => FontChange(e.target.value)}>
                            <option style={{ fontFamily: "Arial" }}value="Arial">Arial</option>
                            <option style={{ fontFamily: "Verdana" }}value="Verdana">Verdana</option>
                            <option style={{ fontFamily: "Cursive" }}value="cursive">cursive</option>
                        </select>
                        <select id="textweight" className="form-select form-select-sm" onChange={(e) => WeightChange(e.target.value)}>
                            <option className="fw-bold" value="bolder">Très gras</option>
                            <option className="fw-normal" value="normal"style={{ fontWeight: "normal" }}selected>Normal</option>
                        </select>
                    </div>
                </div>
                <div className="cvoption-container row pt-3">
                    <div className="col-md-6">
                        <label for="textsizelabel" className="col-form-label">Taille des titres</label>
                    </div>
                    <div className="range col-md-6 pt-2">
                        <input id="textsize" type="range" className="form-range" onChange={(e) => TextSizeChange(e.target.value)}/>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default Personnalisation;