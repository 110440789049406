import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
export const ModalAdd = ({handleClose}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const UserId = localStorage.getItem('userId');
  const [formData, setFormData] = useState({
    moisDebut: '',
    anneeDebut: '',
    moisFin: '',
    anneeFin: '',
    poste: '',
    nomEntreprise: '',
    lieu: '',
    details: '',
customUser:UserId
    
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post(`${process.env.REACT_APP_API_URL}/sbExpPro/`, formData)
      .then((response) => {
        Toast.fire({
          title: "Expérience créée",
          text:"Veuillez actualiser la page",
          icon: "success",
        });
        // Traitez la réponse du serveur ici
        setFormData({
          moisDebut: '',
          anneeDebut: '',
          moisFin: '',
          anneeFin: '',
          poste: '',
          nomEntreprise: '',
          lieu: '',
          details: '',
          customUser:UserId
        });
        // Fermez le modal après la soumission
        handleClose();
      })
      .catch((error) => {
        // Gérez les erreurs ici
        Toast.fire({
          title: "Une erreur s'est produite lors de l'ajout",
          icon: "error",
        });
        
      });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Form.Group as={Col} controlId="formMonth" xs={4}>
          <Form.Label className="fw-bold">Mois du début</Form.Label>
          <Form.Select
            
            name="moisDebut"
            value={formData.moisDebut}
            onChange={handleChange}
          >
            <option value="00">--mois--</option> <option value="01">Janvier</option>
            <option value="02">Février</option>
            <option value="03">Mars</option>
            <option value="04">Avril</option>
            <option value="05">Mai</option>
            <option value="06">Juin</option>
            <option value="07">Juillet</option>
            <option value="08">Août</option>
            <option value="09">Septembre</option>
            <option value="10">Octobre</option>
            <option value="11">Novembre</option>
            <option value="12">Décembre</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formYear" xs={4}>
          <Form.Label className="fw-bold">Année du début</Form.Label>
          <Form.Control type="number" placeholder="Année"
            name="anneeDebut" value={formData.anneeDebut} onChange={handleChange} min="1990" max="2035"/>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="formMonth" xs={4}>
          <Form.Label className="fw-bold">Mois de fin</Form.Label>
          <Form.Select
            
            name="moisFin"
            value={formData.moisFin}
            onChange={handleChange}
          >
            <option value="00">--mois--</option> <option value="01">Janvier</option>
            <option value="02">Février</option>
            <option value="03">Mars</option>
            <option value="04">Avril</option>
            <option value="05">Mai</option>
            <option value="06">Juin</option>
            <option value="07">Juillet</option>
            <option value="08">Août</option>
            <option value="09">Septembre</option>
            <option value="10">Octobre</option>
            <option value="11">Novembre</option>
            <option value="12">Décembre</option>
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="formYear" xs={4}>
          <Form.Label className="fw-bold">Année de fin</Form.Label>
          <Form.Control type="number" placeholder="Année" name="anneeFin" value={formData.anneeFin} onChange={handleChange} min="1990" max="2035" />
        </Form.Group>
      </Row>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Poste</Form.Label>
        <Form.Control type="text" placeholder="Poste" name="poste" value={formData.poste} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formCompanyName">
        <Form.Label className="fw-bold">Nom de l'entreprise</Form.Label>
        <Form.Control type="text" placeholder="Nom de l'entreprise"
          name="nomEntreprise" value={formData.nomEntreprise} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formLocation">
        <Form.Label className="fw-bold">Lieu</Form.Label>
        <Form.Control type="text" placeholder="Lieu" name="lieu" value={formData.lieu} onChange={handleChange} />
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label className="fw-bold">Description</Form.Label>
        <Form.Control as="textarea" rows={4} placeholder="Description" name="details" value={formData.details} onChange={handleChange} />
      </Form.Group>
      <Button
        type="submit"
        style={{
          backgroundColor: "#264a67",
          border: "none",
          marginTop:"10px"
        }}
        onClick={handleSubmit}
      >
        Ajouter
      </Button>
    </Form>
  );
};
export default ModalAdd;
