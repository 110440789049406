import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./Inscription.css";
import { reset_password } from '../../actions/auth';
import { NavLink, useNavigate } from "react-router-dom";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from "sweetalert2";
const ForgetPassword = ({ reset_password }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
    
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    const navigate = useNavigate();

    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        reset_password(email);
        setRequestSent(true);
        setFormData({
            email:""
        })
    };

    if (requestSent) {
        Swal.fire(
            
            'Un lien de réinitialisation de mot de passe vous a été envoyé par email.',
            'success'
          )
    }
    return (
        <Container>
            <h1 className="text-black text-center fw-bold" style={{ marginTop: "25%" }}>Vous avez oublié votre mot de passe ?</h1>
            <h6 className="d-flex justify-content-center align-items-center">Entrez votre adresse e-mail afin que nous puissions vous envoyer un lien de réinitialisation de votre mot de passe.</h6>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2} sm={4} md={4} >

                        <form onSubmit={e => onSubmit(e)}>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                style={{ marginLeft: "100%" }}
                                className="p-3 mt-4"
                                value={email}
                                onChange={e => onChange(e)}
                                required
                            />
                            <Button style={{ marginLeft: "135%" }} className="p-2 mt-4 valide" type='submit'>Soumettre</Button>
                        </form>
                        <div className="d-flex " style={{ marginLeft: "100%",width:"150%" }}>
                          <p>Vous avez déjà un compte ? </p><NavLink to="/connexion" className="p-1" style={{ color:"#264a67" }}>Se connecter</NavLink>
                        </div>

                    </Grid>


                </Grid>

            </Box>

        </Container>
    );
};
export default connect(null, { reset_password })(ForgetPassword);