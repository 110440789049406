import axios from "axios";
import Axios from "./caller.service.js";

let getCv = (cvId) => {
  return Axios.get("/cv/" + cvId);
};
let getLettre = (lettreId) => {
  return Axios.get("/lettre/" + lettreId);
};
let getpatient = () => {
  return Axios.get('/user/');
  };
let getCvs = (userId) => {
  return Axios.get("/cv/?customUser=" + userId);
};
let getLettres = (userId) => {
  return Axios.get("/lettre/?customUser=" + userId);
};

let getAllCvs = () => {
  return Axios.get("/cv/");
};

let createCv = (nomCv, poste, date, userId,userEmail) => {
  return Axios.post("/cv/", {
    nomCv: nomCv,
    poste: poste,
    dat_dern_modif: date,
    customUser: userId,
    email: userEmail,
  });
  
};
let createLettre = (nomLettre, poste, date, userId,userEmail) => {
  return Axios.post("/lettre/", {
    nomLettre: nomLettre,
    poste: poste,
    dat_dern_modif: date,
    customUser: userId,
    email: userEmail,
  });
  
};
let ajoutTel =(telephone) => {
  return Axios.post("/user/", {
    telephone:  telephone,
    email: userEmail
  });
}

let saveCvId = (id) => {
  localStorage.setItem('cvId', id)
};
let saveLettreId = (id) => {
  localStorage.setItem('lettreId', id)
};

export const cvService = {
  getCv,
  getCvs,
  getpatient,
  getAllCvs,
  createCv,
  saveCvId,
  getLettre,
  getLettres,
  createLettre,
  saveLettreId
};
