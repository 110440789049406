import React, { useState, useEffect, useRef } from "react";
import Axios from "../../_services/caller.service";
import { cvService } from "../../_services/cv.service";
import axios from 'axios';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./Sidebar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import QRCode from "react-qr-code";
import ima from "../Image/modèle.png";
import imo from "../Image/Modèle2.png";
import ims from "../Image/modèle3.png";
import { connect } from "react-redux";
import { load_user } from "../../actions/auth";
export const Sidebar = ({
  user,
  load_user,
  onBgColorChange,
  onBg2ColorChange,
  onBg3ColorChange,
  onBg4ColorChange,
  onPhotoRoundedChange,
  onMajuscule,
  props,
  onFileChange,
  handleTemplateSelect,
  handleFontChange,
  handleWeightChange,
  FontChange,
  WeightChange,
  onBg1ColorChange,
  onTextSizeChange,
  onInputChange,
  onInputChange1,
  onInputChange2,
  onInputChange3,
  onInputChange4,
  onInputChange5,
  onInputChange6,
  onInputChange7,
  TextSizeChange,
  onCheckedChange,
  onCheckedChange2,
  onCheckedChange3,
  onCheckedChange4,
  onCheckedChange5,
  onCheckedChange6,
  onCheckedChange7,
  onCvWidth,
  handleCheck1,
  handleCheck3,
  handleCheck5,
  handleCheck7,
  handleCheck9,
  handleCheck11,
  handleCheck13
}) => {
  /* const [menuBarVisible, setMenuBarVisible] = useState(false); */
  const [show, setShow] = useState(false);
  const Handleshow = () => setShow(true);
  const Handleclose = () => setShow(false);
  const [activeButton, setActiveButton] = useState(null);
  const [telephone, setNumeroTelephone] = useState('');
  const [emailData, setEmailData] = useState({
    email: "",
  });
  const [posteData, setPosteData] = useState({
    poste: "",
  });    
  const handleChangeEmail = (event) => {
    const { name, value } = event.target;
    setEmailData({ ...emailData, [name]: value });
    onInputChange2(event.target.value)

  }; 
  const handleChangePoste = (event) => {
    const { name, value } = event.target;
    setPosteData({ ...posteData, [name]: value });
    onInputChange1(event.target.value)
  }; 
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId === activeButton ? null : buttonId);
    if (activeButton !== null) {
      // Effectuer des actions spécifiques lorsque le bouton est désactivé
      console.log('Le bouton est désactivé.');
      CVWidth2();
    }
  };
  const CVWidth = () => {
    const cvContent = document.getElementById("cv-container");
    if (cvContent) {
      const textElements = cvContent.querySelectorAll(".cv-template-sheet");

      textElements.forEach((element) => {
        element.style.marginLeft = "36%";
      });
    }
  };
  const CVWidth2 = () => {
    const cvContent = document.getElementById("cv-container");
    if (cvContent) {
      const textElements = cvContent.querySelectorAll(".cv-template-sheet");

      textElements.forEach((element) => {

        element.style.marginLeft = "20%";
      });
    }
  };
  const Close = () => {
    setActiveButton(null); // Masquer complètement le menu en définissant activeButton à null
    CVWidth2();
  };
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [isQRCodeGenerated, setIsQRCodeGenerated] = useState(false);

  const handleInputChange = (event) => {
    setQrCodeValue(event.target.value);
    setIsQRCodeGenerated(false);
  };
  const handleNumeroTelephoneChange = (e) => {
    setNumeroTelephone(e.target.value);
    // Déclenchez la requête API ici lorsque la saisie change
    saveDataToDatabase(); // Appelez la fonction pour enregistrer les données dans la base de données
  };
  const [userInfo, setUserInfo] = useState([]);
  const UserId = localStorage.getItem('userId');

  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setUserInfo(response.data);
          setEmailData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  const saveDataToDatabase = () => {
    // Effectuez un appel d'API pour enregistrer les données dans la base de données       
    axios.post(`${process.env.REACT_APP_API_URL}/user/${UserId}/`, {
      telephone,
    })
      .then((response) => {
        console.log('Données enregistrées avec succès !');
      })
      .catch((error) => {
        console.error('Erreur lors de l\'enregistrement des données :', error);
      });
  };

  const handleGenerateQRCode = () => {
    setIsQRCodeGenerated(true);
  };

  const [cv, setCv] = useState([]);
  const flag = useRef(false);
  const cvId = localStorage.getItem('cvId');
  useEffect(() => {
    if (cvId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
        .then((response) => {
          setCv(response.data);
          setPosteData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [cvId]);
  const [poste, setPoste] = useState(''); // État local pour stocker la valeur du poste
  const handleChangeIsUppercase = (isUppercase) => {
    setIsUppercase(true)
    setIsNotUppercase(false)
    onMajuscule(true)
  };
  const handleChangeIsNotUppercase = (isUppercase) => {
    setIsUppercase(false)
    setIsNotUppercase(true)
    onMajuscule(false)
  };
  const handleChangeIsRounded = (isRounded) => {
    setIsRounded(true)
    setIsNotRounded(false)
    onPhotoRoundedChange(true)
  };
  const handleChangeIsNotRounded = (isRounded) => {
    setIsRounded(false)
    setIsNotRounded(true)
    onPhotoRoundedChange(false)
  };
  var config = JSON.parse(localStorage.getItem('configurations'));
  let adresse_value = ''
  if (config.informations.adresse){
    adresse_value = config.informations.adresse
  }else{
    adresse_value = userInfo.adresse
  }
  let telephone_value = ''
  if (config.informations.adresse){
    telephone_value = config.informations.telephone
  }else{
    telephone_value = userInfo.telephone
  }
  let datNaiss_value = ''
  if (config.informations.dateNaissance){
    datNaiss_value = config.informations.dateNaissance
  }else{
    datNaiss_value = userInfo.datNais
  }
  let nationalite_value = ''
  if (config.informations.adresse){
    nationalite_value = config.informations.Nationalite
  }else{
    nationalite_value = userInfo.nationalite
  }
  let siteWeb_value = ''
  if (config.informations.siteWeb){
    siteWeb_value = config.informations.siteWeb
  }else{
    siteWeb_value = userInfo.siteWeb
  }
  let situaMatrimoniale_value = ''
  if (config.informations.situaMatrimoniale){
    situaMatrimoniale_value = config.informations.adresse
  }else{
    situaMatrimoniale_value = userInfo.situaMat
  }
  /* const cvposte = localStorage.getItem('new_poste');
  const userEmail = localStorage.getItem('email');
  const userTelephone = config.informations.telephone;
  const userAdresse = config.informations.adresse;
  const userDateNais = config.informations.dateNaissance;
  const userNation = config.informations.Nationalite;
  const userSituation = config.informations.situaMatrimoniale;
  const userSite = config.informations.siteWeb; */
  const savedColor = config.Mise_en_page.Couleur1;
  const saved2Color = config.Mise_en_page.Couleur2;
  const saved3Color = config.Mise_en_page.Couleur3;
  const saved4Color = config.Mise_en_page.Couleur4;
  const savedselectedTextFont = config.Mise_en_page.Police_texte;
  const savedTextFont = config.Mise_en_page.Police_titre;
  const savedTextWeight = config.Mise_en_page.Format_titre;
  const savedselectedTextWeight = config.Mise_en_page.Format_texte;
  //const storedIsPhotoRounded = localStorage.getItem('isPhotoRounded');
  //const storedIsNameUppercase = localStorage.getItem('isNameUppercase');

  const [isEmailVisibleChecked, setIsEmailVisibleChecked] = useState(config.Visibilite_informations.email_visible);
  const [isAdresseVisibleChecked, setIsAdresseVisibleChecked] = useState(config.Visibilite_informations.adresse_visible);
  const [isTelVisibleChecked, setIsTelVisibleChecked] = useState(config.Visibilite_informations.telephone_visible);
  const [isDatNaisChecked, setIsDatNaisChecked] = useState(config.Visibilite_informations.dateNaissance_visible);
  const [isSituaMatChecked, setIsSituaMatChecked] = useState(config.Visibilite_informations.situaMatrimoniale_visible);
  const [isSiteWebChecked, setIsSiteWebChecked] = useState(config.Visibilite_informations.siteWeb_visible);
  const [isNationVisibleChecked, setIsNationVisibleChecked] = useState(config.Visibilite_informations.Nationalite_visible);
  const [isUppercase, setIsUppercase] = useState(config.Mise_en_page.Nom_majuscule === true);
  const [isNotUppercase, setIsNotUppercase] = useState(config.Mise_en_page.Nom_majuscule === false);
  const [isRounded, setIsRounded] = useState(config.Mise_en_page.Photo_arrondie === true);
  const [isNotRounded, setIsNotRounded] = useState(config.Mise_en_page.Photo_arrondie === false);

function updateInDB(value){
  axios.patch(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`, {configurations: value});
}

  // Fonction pour gérer les changements de la case à cocher
  const emailHandleCheck = (event) => {
    const newValue = event.target.checked;
    
    //Mettre à jour les infos dans le localstorage
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.email_visible = newValue
    localStorage.setItem("configurations", JSON.stringify(configjson))

    //Mettre à jour avec le hook
    setIsEmailVisibleChecked(newValue);

    //Mette à jour dans la bdd
    updateInDB(configjson)

    //Mettre à jour sur le cv
    handleCheck1(newValue)
  };
  const TelhandleCheck = (event) => {
    const newValue2 = event.target.checked;
    //Mettre à jour les infos dans le localstorage
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.telephone_visible = newValue2
    localStorage.setItem("configurations", JSON.stringify(configjson))

    setIsTelVisibleChecked(newValue2);
    //Mette à jour dans la bdd
    updateInDB(configjson)
    handleCheck3(newValue2)
  };
  const AdressehandleCheck = (event) => {
    const newValue3 = event.target.checked;
    //Mettre à jour les infos dans le localstorage
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.adresse_visible = newValue3
    localStorage.setItem("configurations", JSON.stringify(configjson))
    setIsAdresseVisibleChecked(newValue3);
    //Mette à jour dans la bdd
    updateInDB(configjson)
    handleCheck5(newValue3)  
  };
  const DateNaishandleCheck = (event) => {
    const newValue4 = event.target.checked;
    //Mettre à jour les infos dans le localstorage
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.dateNaissance_visible = newValue4
    localStorage.setItem("configurations", JSON.stringify(configjson))
    setIsDatNaisChecked(newValue4);
 //Mette à jour dans la bdd
 updateInDB(configjson)
  handleCheck7(newValue4)
  };
  const SituaMathandleCheck = (event) => {
    const newValue6 = event.target.checked;
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.situaMatrimoniale_visible = newValue6
    localStorage.setItem("configurations", JSON.stringify(configjson))
    setIsSituaMatChecked(newValue6);
 //Mette à jour dans la bdd
 updateInDB(configjson)
 handleCheck9(newValue6)
  };
  const SiteWebhandleCheck = (event) => {
    const newValue8 = event.target.checked;
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.siteWeb_visible = newValue8
    localStorage.setItem("configurations", JSON.stringify(configjson))
    setIsSiteWebChecked(newValue8);
 //Mette à jour dans la bdd
 updateInDB(configjson)
 handleCheck11(newValue8)
  };
  const NationalitehandleCheck = (event) => {
    const newValue10 = event.target.checked;
    var configjson = JSON.parse(localStorage.getItem('configurations'));
    configjson.Visibilite_informations.Nationalite_visible = newValue10
    localStorage.setItem("configurations", JSON.stringify(configjson))
    setIsNationVisibleChecked(newValue10);
 //Mette à jour dans la bdd
 updateInDB(configjson)
 handleCheck13(newValue10)
  };

  return (
    <div className="row">
      <div className="row">
        <div className=" sidebar d-flex justify-content-between align-items-center flex-column text-white p-3  vh-100">
          <div className="sub-menu">
            <ul
              className="nav nav-pills flex-column mt-3 sidebar-menu "
              id="nav-accordion"
            >
              <li
                className={`nav-item ${activeButton === 1 ? "active" : ""}`}
                onClick={() => {
                  handleButtonClick(1);
                  CVWidth();
                }}
              >
                <span className=" text-white">
                  <i className="bi bi-columns me-3 "></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }} onClick={CVWidth2}>Modèles</span>
                </span>
              </li>
              <li
                className={`nav-item ${activeButton === 4 ? "active" : ""}`}
                onClick={Handleshow}
              >
                <span className=" text-white">
                  <i className="bi bi-camera-fill me-3 "></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }}>Photo de profil</span>
                </span>
              </li>
              <li
                className={`nav-item ${activeButton === 2 ? "active" : ""}`}
                onClick={() => {
                  handleButtonClick(2);
                  CVWidth();
                }}
              >
                <span className="text-white">
                  <i className="bi bi-list-task me-3 "></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }}>Informations</span>
                </span>
              </li>
              <li
                className={`nav-item ${activeButton === 3 ? "active" : ""}`}
                onClick={() => {
                  handleButtonClick(3);
                  CVWidth();
                }}
              >
                <span className="text-white">
                  <i className="bi bi-pencil-fill me-3 "></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }}>Mise en page</span>
                </span>
              </li>

              {/*<li
                className={`nav-item ${activeButton === 6 ? "active" : ""}`}
                onClick={() => {
                  handleButtonClick(6);
                  CVWidth();
                }}
              >
                <span className=" text-white">
                  <i className="bi bi-qr-code me-3"></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }}>QRCode</span>
                </span>
              </li>
              <li
                className={`nav-item ${activeButton === 7 ? "active" : ""}`}
                onClick={() => {handleButtonClick(7);CVWidth()}}
              >
                <span className=" text-white">
                  <i className="bi bi-plus-circle me-3 fs-6"></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }}>Ajouter un bloc</span>
                </span>
              </li>
               <li
                className={`nav-item ${activeButton === 8 ? "active" : ""}`}
                onClick={() => handleButtonClick(8)}
              >
                <span className=" text-white">
                  <i className="bi bi-stars me-3 "></i>
                  <br />
                  <span style={{ fontSize: "13.5px" }}>Révéler l'éditeur</span>
                </span>
              </li> */}
            </ul>
          </div>
          <div>
            {activeButton === 1 && (
              <div
                className={`menu-content ${activeButton === 1 ? "show" : ""}`}
              >
                <div className="menu-bar menu-bar--templates lg">
                  <div className="menu-bar__header lg">
                    <div className="back">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                        onClick={Close}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                    </div>
                    <div className="title">Modèles</div>
                  </div>
                  <div className="menu-bar__content">
                    <Row>
                      <Col>
                        <img
                          src={ima}
                          style={{ width: "152px", height: "215,69px" }}
                          alt=""
                          onClick={() => handleTemplateSelect("Modele1")}
                        />
                      </Col>
                      <Col>
                        <img
                          src={imo}
                          style={{ width: "152px", height: "215,69px" }}
                          alt=""
                          onClick={() => handleTemplateSelect("Modele2")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <img
                          src={ims}
                          style={{ width: "152px", height: "215,69px" }}
                          alt=""
                          onClick={() => handleTemplateSelect("Modele3")}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {activeButton === 2 && (
              <div
                className={`menu-content ${activeButton === 2 ? "show" : ""}`}
              >
                <div className="menu-bar menu-bar--info lg">
                  <div className="menu-bar__header lg">
                    <div className="back">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                        onClick={Close}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                    </div>
                    <div className="title">
                      Modifier les informations de base
                    </div>
                  </div>
                  <div className="menu-bar__content">
                    <div className="options-infos">
                      <div>
                        <label for="info-job-input" className="form-label">
                          Poste
                        </label>
                        <input
                          type="text"
                          className="form-control info-input"
                          id="info-job-input"
                          name="poste"
                          value={posteData.poste}
                          onChange={handleChangePoste}
                        />
                      </div>
                      <div className="mb-3">
                        <label for="info-email-input" className="form-label">
                          Email
                        </label>
                        <Row>
                          <Col>
                            <input
                              type="email"
                              className="form-control info-input"
                              id="info-email-input"
                              name="email"
                              value={emailData.email}
                              onChange={handleChangeEmail}
                              //placeholder={userInfo.email}
                              style={{ width: "270%" }}

                            />
                          </Col>
                          <Col
                            className=" checkbox-wrapper-25  pt-2"
                            style={{ marginLeft: "40%" }}
                          >
                            <input
                              type="checkbox"
                              checked={isEmailVisibleChecked}
                              style={{ marginRight: "3px" }}
                              onChange={emailHandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">


                        <label
                          for="info-phone-input"
                          className="form-label"
                        >
                          Numéro de téléphone
                        </label>
                        <Row>
                          <Col>
                            <input
                              type="text"
                              className="form-control info-input"
                              id="info-phone-input"
                              name="telephone"
                              defaultValue={telephone_value}
                              style={{ width: "270%" }}
                              //placeholder={userInfo.telephone}
                              onChange={(event) => onInputChange(event.target.value)}

                            />
                          </Col>
                          <Col
                            className=" checkbox-wrapper-25  pt-2"
                            style={{ marginLeft: "40%" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "3px" }}
                              checked={isTelVisibleChecked}
                              onChange={TelhandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>

                          <label
                            for="info-citylive-input"
                            className="form-label"
                          >
                            Adresse de résidence
                          </label>
                          <Col>
                            <input
                              type="text"
                              className="form-control info-input"
                              id="info-citylive-input"
                              //value={adresse_value}
                              //placeholder={userInfo.adresse}
                              defaultValue={adresse_value}
                              onChange={(event) => onInputChange3(event.target.value)}
                              name="adresse"
                              style={{ width: "270%" }}
                            />
                          </Col>
                          <Col
                            className=" checkbox-wrapper-25  pt-2"
                            style={{ marginLeft: "40%" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "3px" }}
                              checked={isAdresseVisibleChecked}
                              onChange={AdressehandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <label
                            for="info-birthday-input"
                            className="form-label"
                          >
                            Date de naissance
                          </label>
                          <Col>

                            <input
                              type="date"
                              className="form-control info-input"
                              id="info-birthday-input"
                              defaultValue={datNaiss_value}
                              //placeholder={userInfo.datNais}
                              style={{ width: "140%" }}
                              name="datNais"
                              onChange={(event) => onInputChange4(event.target.value)}

                            />
                          </Col>
                          <Col
                            className=" checkbox-wrapper-25  pt-2"
                            style={{ marginLeft: "20%" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "3px" }}
                              checked={isDatNaisChecked}
                              onChange={DateNaishandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>

                      </div>

                      <div className="mb-3">
                        <Row>
                          <label
                            for="info-situamarit-input"
                            className="form-label"
                          >
                            Situation matrimoniale
                          </label>
                          <Col>
                            <input
                              type="text"
                              className="form-control info-input"
                              id="info-website-input"

                              style={{ width: "250%" }}
                              //placeholder={userInfo.situaMat}
                              defaultValue={situaMatrimoniale_value}
                              name="situaMat"
                              onChange={(event) => onInputChange5(event.target.value)}
                            />

                          </Col>
                          <Col
                            className=" checkbox-wrapper-25  pt-2"
                            style={{ marginLeft: "40%" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "2px" }}
                              checked={isSituaMatChecked}
                              onChange={SituaMathandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>
                      </div>

                      <div className="mb-3">
                        <Row>
                          <label
                            for="info-website-input"
                            className="form-label"
                          >
                            Site Web
                          </label>
                          <Col>

                            <input
                              type="text"
                              className="form-control info-input"
                              id="info-website-input"

                              style={{ width: "250%" }}
                              //placeholder={userInfo.siteWeb}
                              name="siteWeb"
                              defaultValue={siteWeb_value}
                              onChange={(event) => onInputChange6(event.target.value)}
                            />
                          </Col>
                          <Col
                            className=" checkbox-wrapper-25 pt-2"
                            style={{ marginLeft: "40%" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "5px" }}
                              checked={isSiteWebChecked}
                              onChange={SiteWebhandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-3">
                        <Row>
                          <label
                            for="info-nationalite-input"
                            className="form-label"
                          >
                            Nationalité
                          </label>
                          <input
                            type="text"
                            className="form-control info-input"
                            id="info-nationalite-input"

                            style={{ width: "60%" }}
                            //placeholder={userInfo.nationalite}
                            defaultValue={nationalite_value}
                            name="nationalite"
                            onChange={(event) => onInputChange7(event.target.value)}
                          />
                          <Col
                            className=" checkbox-wrapper-25 pt-2"
                            style={{ marginLeft: "10%" }}
                          >
                            <input
                              type="checkbox"
                              style={{ marginRight: "5%" }}
                              checked={isNationVisibleChecked}
                              onChange={NationalitehandleCheck}
                            />
                            <label
                              className="form-check-label"
                              for="info-birthday-visiblecheck"
                            >
                              Visible
                            </label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeButton === 3 && (
              <div
                className={`menu-content ${activeButton === 3 ? "show" : ""}`}
              >
                <div className="menu-bar menu-bar--info lg">
                  <div className="menu-bar__header lg">
                    <div className="back">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                        onClick={Close}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                    </div>
                    <div className="title">
                      Personnaliser votre cv
                    </div>
                  </div>
                  <div className="menu-bar__content">
                    <div className="options-misenpag">
                      <div className="option-misenpag pt-3">
                        <h5 className="fw-600 text-black">
                          Couleurs / Marges / Polices
                        </h5>
                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="bg1colorlabel"
                              className="col-form-label"
                            >
                              Couleur de fond
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="color"
                              id="bgColor"
                              className="form-control form-control-color"
                              title="Choisir la couleur"
                              value={savedColor}
                              onChange={(event) =>
                                onBgColorChange(event.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="bg2colorlabel"
                              className="col-form-label"
                            >
                              Couleur primaire
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="color"
                              id="bg2color"
                              className="form-control form-control-color"
                              title="Choisir la couleur"
                              value={saved2Color}
                              onChange={(event) =>
                                onBg2ColorChange(event.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="bg3colorlabel"
                              className="col-form-label"
                            >
                              Couleur sécondaire
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="color"
                              id="bg3color"
                              className="form-control form-control-color"
                              title="Choisir la couleur"
                              value={saved3Color}
                              onChange={(event) =>
                                onBg3ColorChange(event.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="bg3colorlabel"
                              className="col-form-label"
                            >
                              Autre Couleur
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="color"
                              id="bg4color"
                              className="form-control form-control-color"
                              title="Choisir la couleur"
                              value={saved4Color}
                              onChange={(event) =>
                                onBg4ColorChange(event.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="cvoption-container row g-3 pt-2">
                          <div className="col-md-6">
                            <label
                              for="namecolorlabel"
                              className="col-form-label"
                            >
                              Photo arrondie
                            </label>
                          </div>
                          <div className="col-md-6 pt-2" id="cv-photo-rounded">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cv-photo-rounded-choice"
                                type="radio"
                                name="cv-photo-rounded-choice"
                                checked={isRounded}
                                onChange={handleChangeIsRounded}
                              />
                              <label className="form-check-label">Oui</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cv-photo-rounded-choice"
                                type="radio"
                                name="cv-photo-rounded-choice"
                                checked={isNotRounded}
                                onChange={handleChangeIsNotRounded}
                              />
                              <label className="form-check-label">Non</label>
                            </div>
                          </div>
                        </div>

                        <div className="cvoption-container row g-3 pt-2">
                          <div className="col-md-6">
                            <label
                              for="namecolorlabel"
                              className="col-form-label"
                            >
                              Nom majuscule
                            </label>
                          </div>
                          <div className="col-md-6 pt-2">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cv-name-upper"
                                type="radio"
                                name="cv-name-upper"
                                checked={isUppercase}
                                onChange={handleChangeIsUppercase}
                              />
                              <label className="form-check-label">Oui</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input cv-name-upper"
                                type="radio"
                                name="cv-name-upper"
                                checked={isNotUppercase}
                                onChange={handleChangeIsNotUppercase}
                              />
                              <label className="form-check-label">Non</label>
                            </div>
                          </div>
                        </div>

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="textfontlabel"
                              className="col-form-label"
                            >
                              Police des textes
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              id="textfont"
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={savedselectedTextFont}
                              onChange={(e) => handleFontChange(e.target.value)}
                            >

                              <option
                                style={{ fontFamily: "Arial" }}
                                value="Arial"
                              >
                                Arial
                              </option>
                              <option
                                style={{ fontFamily: "Georgia" }}
                                value="Georgia"
                              >
                                Georgia
                              </option>
                              <option
                                style={{ fontFamily: "Verdana" }}
                                value="Verdana"
                              >
                                Verdana
                              </option>
                              <option
                                style={{ fontFamily: "Cursive" }}
                                value="cursive"
                              >
                                cursive
                              </option>
                              <option
                                style={{ fontFamily: "fantasy" }}
                                value="fantasy"
                              >
                                fantasy
                              </option>
                            </select>

                            <select
                              id="textweight"
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={savedselectedTextWeight}
                              onChange={(e) => handleWeightChange(e.target.value)}
                            >
                              <option className="fw-bold" value="bolder">
                                Très gras
                              </option>

                              <option
                                className="fw-normal"
                                value="normal"
                                style={{ fontWeight: "normal" }}
                                selected
                              >
                                Normal
                              </option>
                              <option className="fw-light" value="lighter" style={{ fontWeight: "lighter" }}>
                                Léger
                              </option>
                            </select>
                          </div>
                        </div>

                        {/*<div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="textsizelabel"
                              className="col-form-label"
                            >
                              Taille des textes
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="textsize"
                              type="range"
                              className="form-range"
                              onChange={(event) => onTextSizeChange(parseInt(event.target.value,10))}
                            />
                          </div>
                        </div>

                         <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="namesizelabel"
                              className="col-form-label"
                            >
                              Taille du nom
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="namesize"
                              type="range"
                              className="form-range"
                              onChange={(event) => TextSizeChange(parseInt(event.target.value,10))}
                            />
                          </div>
                        </div> */}

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="titlefontlabel"
                              className="col-form-label"
                            >
                              Police des titres
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              id="textfont"
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={savedTextFont}
                              onChange={(e) => FontChange(e.target.value)}
                            >

                              <option
                                style={{ fontFamily: "Arial" }}
                                value="Arial"
                              >
                                Arial
                              </option>
                              <option
                                style={{ fontFamily: "Georgia" }}
                                value="Georgia"
                              >
                                Georgia
                              </option>
                              <option
                                style={{ fontFamily: "Verdana" }}
                                value="Verdana"
                              >
                                Verdana
                              </option>
                              <option
                                style={{ fontFamily: "Cursive" }}
                                value="cursive"
                              >
                                cursive
                              </option>
                              <option
                                style={{ fontFamily: "fantasy" }}
                                value="fantasy"
                              >
                                fantasy
                              </option>
                            </select>

                            <select
                              id="titleweight"
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={savedTextWeight}
                              onChange={(e) => WeightChange(e.target.value)}
                            >
                              <option className="fw-bold" value="bolder">
                                Très gras
                              </option>

                              <option
                                className="fw-normal"
                                value="normal"
                                style={{ fontWeight: "normal" }}
                                selected
                              >
                                Normal
                              </option>
                              <option className="fw-light" value="lighter" style={{ fontWeight: "lighter" }}>
                                Léger
                              </option>
                            </select>
                          </div>
                        </div>

                        {/* <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="namesizelabel"
                              className="col-form-label"
                            >
                              Taille des titres
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="namesize"
                              type="range"
                              className="form-range"
                              min="10"
                              max="25"
                            />
                          </div>
                        </div>

                         <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="namesizelabel"
                              className="col-form-label"
                            >
                              Marges entre blocs
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="namesize"
                              type="range"
                              className="form-range"
                              min="10"
                              max="25"
                            />
                          </div>
                        </div>

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="namesizelabel"
                              className="col-form-label"
                            >
                              Marges entre chaque contenu
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="namesize"
                              type="range"
                              className="form-range"
                              min="10"
                              max="25"
                            />
                          </div>
                        </div>

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="namesizelabel"
                              className="col-form-label"
                            >
                              Marges intérieures verticales
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="namesize"
                              type="range"
                              className="form-range"
                              min="10"
                              max="25"
                            />
                          </div>
                        </div>

                        <div className="cvoption-container row pt-3">
                          <div className="col-md-6">
                            <label
                              for="namesizelabel"
                              className="col-form-label"
                            >
                              Marges intérieures horizontales
                            </label>
                          </div>
                          <div className="range col-md-6 pt-2">
                            <input
                              id="namesize"
                              type="range"
                              className="form-range"
                              min="10"
                              max="25"
                            />
                          </div>
                        </div>

                        <h5 className="fw-600 text-black">Formats</h5>
                        <Form.Group as={Row} className="pt-3">
                          <Form.Label column sm={6}>
                            Format de la date
                          </Form.Label>
                          <Col sm={4}>
                            <Form.Select
                              id="titlefont"
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                            >
                              <option value="Par défaut">Par défaut</option>
                              <option value="07-2023">07-2023</option>
                              <option value="07/2023">07/2023</option>
                              <option value="2023-07">2023-07</option>
                              <option value="2023/07">2023/07</option>
                              <option value="Juil. 2023">Juil. 2023</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="pt-3">
                          <Form.Label column sm={6}>
                            Cacher les icônes des informations
                          </Form.Label>
                          <Col sm={6}>
                            <div className="form-check form-check-inline">
                              <Form.Check
                                className="cv-name-upper"
                                type="radio"
                                name="cv-icon"
                                value="oui"
                                label="Oui"
                              />
                            </div>
                            <div className="form-check form-check-inline">
                              <Form.Check
                                className="cv-name-upper"
                                type="radio"
                                name="cv-icon"
                                value="non"
                                label="Non"
                                checked
                              />
                            </div>
                          </Col>
                        </Form.Group> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeButton === 6 && (
              <div
                className={`menu-content ${activeButton === 6 ? "show" : ""}`}
              >
                <div className="menu-bar menu-bar--logos lg">
                  <div className="menu-bar__header lg">
                    <div className="back">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                        onClick={Close}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                    </div>
                    <div className="title">Insérer un qrcode</div>
                  </div>
                  <div className="menu-bar__content ">
                    <div>
                      <div>
                        <Form>
                          <Form.Control
                            type="text"
                            className="w-75"
                            placeholder="Entrer le contenu de votre code qr"
                            style={{
                              paddingBottom: "8%",
                              marginLeft: "10%",
                            }}
                            value={qrCodeValue}
                            onChange={handleInputChange}
                          />
                          <Button
                            style={{
                              backgroundColor: "#264a67",
                              borderColor: "#264a67",
                              marginTop: "15px",
                              marginBottom: "25px",
                              marginLeft: "35px",
                            }}
                            onClick={handleGenerateQRCode}
                          >
                            Générer le code QR
                          </Button>
                        </Form>
                        {isQRCodeGenerated && qrCodeValue && (
                          <QRCode
                            style={{
                              marginLeft: "35px",
                              height: "auto",
                              maxWidth: "100%",
                              width: "50%",
                            }}
                            value={qrCodeValue}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeButton === 7 && (
              <div
                className={`menu-content ${activeButton === 7 ? "show" : ""}`}
              >
                <div className="menu-bar menu-bar--logos lg">
                  <div className="menu-bar__header lg">
                    <div className="back">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#000"
                        className="bi bi-arrow-left"
                        viewBox="0 0 16 16"
                        onClick={Close}
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                      </svg>
                    </div>
                    <div className="title">Insérer un qrcode</div>
                  </div>
                  <div className="menu-bar__content ">
                    <div>
                      <div>
                        <Form>
                          <Form.Group controlId="formMission">
                            <Form.Label className="fw-bold">
                              Choisir le bloc a ajouté
                            </Form.Label>
                            <Form.Select

                            >
                              <option value="Réseaux">Réseaux</option>
                              <option value="Centre d'intérêt">
                                Centre d'intérêt
                              </option>
                              <option value="Atouts">Atouts</option>
                              <option value="Références">Références</option>
                              <option value="Diplômes et formations">
                                Diplômes et formations
                              </option>
                              <option value="Juin">Juin</option>
                              <option value="Juillet">Juillet</option>
                              <option value="Août">Août</option>
                              <option value="Septembre">Septembre</option>
                              <option value="Octobre">Octobre</option>
                              <option value="Novembre">Novembre</option>
                              <option value="Décembre">Décembre</option>
                            </Form.Select>
                          </Form.Group>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          show={show}
          onHide={Handleclose}
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Photo de profil</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label htmlFor="file-input" className="drop-container">
                  <span className="drop-title">Déposez une photo ici</span>
                  ou
                </Form.Label>
                <InputGroup>
                  <FormControl
                    type="file"
                    accept="image/*"
                    required
                    name="photo"
                    id="profilinput"
                    onChange={onFileChange}
                  />
                </InputGroup>
              </Form.Group>
            </Form>
            <Button
            type="submit"
            style={{
              backgroundColor: "#264a67",
              border: "none",
              marginTop:"10px"
            }}
            onClick={Handleclose}
          >
            Valider
          </Button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  user: state.auth.user
});
export default connect(mapStateToProps, { load_user })(Sidebar);
