import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { verify } from '../actions/auth';
import Swal from "sweetalert2";

const Activate = ({ verify, isActivated }) => {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [verified, setVerified] = useState(false);
    useEffect(() => {
        const verifyAccount = async () => {
            try {
                await verify(uid, token);
                setVerified(true);
            } catch (error) {
                console.error('Error verifying account:', error);
                // Gérer l'erreur ici (par exemple, afficher un message d'erreur)
            }
        };
        verifyAccount();
    }, [uid, token, verify]);

    useEffect(() => {
        if (isActivated && verified) {
            // Afficher l'alerte de succès ici
            Swal.fire({
                icon: "success",
                title: "Votre compte est validé !",
                html: `
		   <div class="overflow-hidden" style="overflow:hidden">
                    <center>Vous pouvez maintenant vous rendre sur la page de connexion pour vous connecter.</center></br>
                    <a href="${process.env.REACT_APP_FRONT_URL}/connexion"class=" text-white" style="background-color:#264a67;border:none;padding:2px;overflow:hidden;">Aller à la page de connexion</a>
                  </div> 
                  `,
		overflow:"hidden",
               showConfirmButton: false,
            });
        }
    }, [verified, isActivated]);

    useEffect(() => {
        if (!isActivated && verified) {
            // Afficher l'alerte d'erreur ici
            Swal.fire({
                icon: "error",
                title: "Oops...",
                html: `
                <div class="overflow-hidden" style="overflow:hidden">
                    <center>Ce lien a expiré !</center></br>
                    <center>Utilisez le lien suivant pour obtenir un nouveau lien d'activation:</center></br>
                    <a href="${process.env.REACT_APP_FRONT_URL}/resend_activation_email"class=" text-white" style="background-color:#264a67;border:none;padding:2px;overflow:hidden;">Obtenez un nouvel email</a>
                 </div>
 `,
		overflow:"hidden",
                 showConfirmButton: false,
            });
        }
    }, [verified, isActivated]);

    return (
        <div className='container'>
            <div className='d-flex flex-column justify-content-center align-items-center' style={{ marginTop: '200px' }}>
                <center><h1>KAPITAL DEV</h1></center>
                <center><h2>Validation de compte</h2></center>
                 {/* Vous pouvez ajouter ici une indication de chargement ou un message */}
            </div>
            
        </div>
    );
};

const mapStateToProps = state => ({
    isActivated: state.auth.isActivated
});

export default connect(mapStateToProps, { verify })(Activate);
