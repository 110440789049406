import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Importez useParams
import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import "./reset.css";
import Swal from 'sweetalert2';
const ResetPasswordConfirm = ({ reset_password_confirm }) => {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
    
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    const navigate = useNavigate();
    const { uid, token } = useParams(); // Utilisez useParams() pour accéder aux paramètres d'URL

    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        // Utilisez les valeurs extraites des paramètres d'URL
        reset_password_confirm(uid, token, new_password, re_new_password);
        setRequestSent(true);
        setFormData({
            new_password: '',
        re_new_password: ''
        });
    };

    if (requestSent) {
        Swal.fire(
            'Mot de passe modifié!',
            'Vous pouvez allez sur page de connexion pour se connecter',
            'success'
          )
    }

    return (
        <div className='tout' style={{overflow:'hidden'}} >
                      
                <div className="bg-white rounded p-10 text-center shadow-md" style={{overflow:'hidden'}}>
                    <h1 className="text-3xl">Réinitialiser votre mot de passe</h1>
                    
                    <form onSubmit={e => onSubmit(e)}>    
                        <div className="my-4 text-left">
                            <input
                            type="password"
                            className="border block w-full p-2 mt-2 rounded"
                            value={new_password} onChange={e => onChange(e)}
                            placeholder="Nouveau password"
                            name="new_password"
                        />
                    </div>

                    <div className="my-4 text-left">
                        
                        <input
                            type="password"
                            className="border block w-full p-2 mt-2 rounded"
                            name="re_new_password"
                            placeholder="Confirmer Nouveau password"
                            value={re_new_password} onChange={e => onChange(e)}
                        />
                    </div>

                    <button
                        className="bg-black text-white py-2 mt-4 inline-block w-full rounded"
                        type="submit"
                    >
                        Réinitialiser
                    </button>
                </form>
                </div>
            

        </div>
    );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
