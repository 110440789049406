import React,{useState} from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
export const Interet = ({handleClose}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const UserId = localStorage.getItem('userId');
  const [formData, setFormData] = useState({
    interet: '',
    details: '',
customUser:UserId
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/sbCentreInteret/`, formData)
      .then((response) => {
        // Traitez la réponse du serveur ici
        Toast.fire({
          title: "Centre d'intérêt créé",
          text:"Veuillez actualiser la page",
          icon: "success",
        });
         setFormData({
          interet: '',
          details: '',
customUser:UserId
         });
         // Fermez le modal après la soumission
        handleClose();
      })
      .catch((error) => {
        // Gérez les erreurs ici
        Toast.fire({
          title: "Une erreur s'est produite lors de l'ajout",
          icon: "error",
        });
        
      });
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Centre d'intérêt</Form.Label>
        <Form.Control type="text" placeholder="Centre d'intérêt" name="interet"
          value={formData.interet} onChange={handleChange}/>
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label className="fw-bold">Description</Form.Label>
        <Form.Control as="textarea" rows={4} placeholder="Description" name="details"
          value={formData.details} onChange={handleChange}/>
      </Form.Group>
      <Button
            type="submit"
            style={{
              backgroundColor: "#264a67",
              border: "none",
              marginTop:"10px"
            }}
            onClick={handleSubmit}
          >
            Ajouter
          </Button>
    </Form>
  );
};
export default Interet;
