import React,{useState} from "react";
import {BsColumnsGap,BsPersonLinesFill,BsFillBrushFill} from "react-icons/bs";
import { Modal } from "react-bootstrap";
import {Info_lettre} from "./Informations_lettre";
import Personnalisation from "./Personnalisation_lettre";
export const Side_lettre = ({props,onBgColorChange,
    onBg2ColorChange,onMajuscule,handleFontChange,
    handleWeightChange,
    FontChange,
    WeightChange,onTextSizeChange,
    TextSizeChange,onCheckedChange,
    onCheckedChange2,
    onCheckedChange3,}) => {
    const [show,setShow] = useState(false);
    const [show2,setShow2] = useState(false);
    const [show3,setShow3] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    return (
        <div>
            <nav id="sidebar" className="gap-3 col-md-2 h-100 flex-column navbar navbar-expand-sm bg-transparent"
            style={{marginTop:"10%",position:"fixed"}}>
            {/* <button type="button" className="btn btn-light"  
               style={{width: "70%"}}
               
               >
                
                <div>
                    <span style={{color: "#ff6600"}}> <BsColumnsGap /> Modèles</span>
                </div>
            </button> */}

            <button type="button" className="btn btn-light" style={{width: "70%"}} onClick={handleShow2}>
                
                <div>
                    <span style={{color: "#ff6600"}}> <BsPersonLinesFill /> Informations</span>
                </div>
            </button>

            <button type="button" className="btn btn-light" style={{width: "70%"}} onClick={handleShow3}>
                
                <div>
                    <span style={{color: "#ff6600"}}> <BsFillBrushFill /> Mise en page</span>
                </div>
            </button>

        </nav>
        <Modal
          show={show2}
          onHide={handleClose2}
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
         
          <Modal.Body>
            <Info_lettre
            onCheckedChange={onCheckedChange}
            onCheckedChange2={onCheckedChange2}
            onCheckedChange3={onCheckedChange3}
             />
          </Modal.Body>
        </Modal>
        <Modal
          show={show3}
          onHide={handleClose3}
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
         
          <Modal.Body>
            <Personnalisation
                onBgColorChange={onBgColorChange}
                onBg2ColorChange={onBg2ColorChange}
                onMajuscule={onMajuscule}
                handleFontChange={handleFontChange}
                handleWeightChange={handleWeightChange}
                FontChange={FontChange}
                WeightChange={WeightChange}
                onTextSizeChange={onTextSizeChange}
                TextSizeChange={TextSizeChange}
            />
          </Modal.Body>
        </Modal>
        </div>
    );
};
export default Side_lettre;