import React,{useState,useEffect} from "react";
import {Form,Row,Col,Button} from "react-bootstrap";
import axios from "axios";
export const Info_lettre = ({
    onCheckedChange,
  onCheckedChange2,
  onCheckedChange3,
}) => {
    const [formData, setFormData] = useState({
        telephone: "",
        email: "",
        ville: "",
        password: ""
      });
      const UserId = localStorage.getItem('userId');
      
      useEffect(() => {
        if (UserId) {
          // Si l'ID du CV existe dans le local storage
          axios.get(`${process.env.REACT_APP_API_URL}/usermodif/${UserId}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
            .then((response) => {
              setFormData(response.data);
            })
            .catch((error) => {
              console.error('Error fetching user info:', error);
            });
        }
      }, [UserId]);
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      }; 
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Utilisez Axios pour envoyer les données au serveur
          const response = await axios.put(`${process.env.REACT_APP_API_URL}/usermodif/${UserId}/`, formData); // Remplacez "/api/update-user" par votre URL backend
          Toast.fire({
            title: "Mise à jour effectuée",
            icon: "success",
          });
        } catch (error) {
          Toast.fire({
            title: "Une erreur s'est produite lors de l'inscription",
            icon: "error",
          });
        }
      };
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <h4>Informations du profil</h4>
                <hr />
                <Row>
                    <Form.Group as={Col} xs={1}>
                        <Form.Label className="fw-bold">Visible</Form.Label>
                        <Form.Check defaultChecked onChange={(event) => onCheckedChange(event.target.value)}/>
                    </Form.Group>

                    <Form.Group as={Col} className="ms-4">
                        <Form.Label className="fw-bold">Email</Form.Label>
                        <Form.Control type="email" placeholder="Année" name="email"
                      value={formData.email}
                      onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={1}>
                        <Form.Label className="fw-bold">Visible</Form.Label>
                        <Form.Check defaultChecked onChange={(event) => onCheckedChange2(event.target.value)}/>
                    </Form.Group>

                    <Form.Group as={Col}className="ms-4">
                        <Form.Label className="fw-bold">Adresse de résidence</Form.Label>
                        <Form.Control type="text" placeholder="Année" name="adresse"
                      value={formData.adresse}
                      onChange={handleChange}/>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} xs={1}>
                        <Form.Label className="fw-bold">Visible</Form.Label>
                        <Form.Check defaultChecked onChange={(event) => onCheckedChange3(event.target.value)}/>
                    </Form.Group>

                    <Form.Group as={Col} className="ms-4" >
                        <Form.Label className="fw-bold">Téléphone</Form.Label>
                        <Form.Control type="number" placeholder="Année" style={{width:"60%"}} value={formData.telephone}
                      onChange={handleChange}
                      name="telephone"/>
                    </Form.Group>
                </Row>
                
                <Button type="submit" variant="success" onClick={handleSubmit}>Modifier</Button>
            </Form>
        </div>
    );
};
export default Info_lettre;