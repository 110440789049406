import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

//import { Tooltip } from "react-tooltip";
import ModalAdd from "./Modaux/AddExperience";
import Diplome from "./Modaux/AddDiplome";
import Competence from "./Modaux/AddCompetence";
import Langue from "./Modaux/AddLangue";
import Interet from "./Modaux/AddInteret";
import ModalMod from "./Modaux/Modif/ModifierExperience";
import ModDiplome from "./Modaux/Modif/ModifierDiplome";
import ModCompetence from "./Modaux/Modif/ModifierCompetence";
import ModLangue from "./Modaux/Modif/ModifierLangue";
import ModInteret from "./Modaux/Modif/ModifierInteret";
import "./Dashboard.css";
import "../Sidebar/Sidebar";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import { userService } from "../../_services/user.service";
import Axios from "../../_services/caller.service";
import axios from "axios";
import { deleteexperience, deletediplome, deletecompetence, deletecentreinteret, deletelangue } from "./ApiService";
const Modele3 = ({ downloadPDF, bgColor, bg2Color, bg3Color, bg4Color, imageUrl, isPhotoRounded, isNameUppercase,
  selectedTextFont, selectedTextWeight, TextFont, TextWeight, textSize, text2Size, inputPhone, inputPoste, inputEmail, inputAdresse, inputDatNais,
  inputSituation, inputSite, inputNationalite, emailVisible, numVisible,
  adresseVisible, user, load_user, datVisible, natioVisible, siteVisible, situaVisible }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [showButtons1, setShowButtons1] = useState(false);
  const [showButtons2, setShowButtons2] = useState(false);
  const [showButtons3, setShowButtons3] = useState(false);
  const [showButtons4, setShowButtons4] = useState(false);
  const [showButtons5, setShowButtons5] = useState(false);
  const [showButtons6, setShowButtons6] = useState(false);
  const [showButtons7, setShowButtons7] = useState(false);
  const [showButtons8, setShowButtons8] = useState(false);
  const [showButtons9, setShowButtons9] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddModal1, setShowAddModal1] = useState(false);
  const [showAddModal2, setShowAddModal2] = useState(false);
  const [showAddModal3, setShowAddModal3] = useState(false);
  const [showAddModal4, setShowAddModal4] = useState(false);
  const [showAddModal5, setShowAddModal5] = useState(false);
  const [showAddModal6, setShowAddModal6] = useState(false);
  const [showAddModal7, setShowAddModal7] = useState(false);
  const [showAddModal8, setShowAddModal8] = useState(false);
  const [showAddModal9, setShowAddModal9] = useState(false);
  //const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAddClick = () => {
    setShowAddModal(true);
    //setShowDeleteModal(false);
  };
  const handleAddClick1 = () => {
    setShowAddModal1(true);
  };
  const AddDiplome = () => {
    setShowAddModal2(true);
  };
  const ModifDiplome = () => {
    setShowAddModal3(true);
  };
  const AddCompetence = () => {
    setShowAddModal4(true);
  };
  const ModifCompetence = () => {
    setShowAddModal5(true);
  };
  const AddInteret = () => {
    setShowAddModal6(true);
  };
  const ModifInteret = () => {
    setShowAddModal7(true);
  };
  const AddLangue = () => {
    setShowAddModal8(true);
  };
  const ModifLangue = () => {
    setShowAddModal9(true);
  };

  const handleDeleteClick = () => {
    setShowAddModal(false);
    //setShowDeleteModal(true);
  };

  const handleCloseModals = () => {
    setShowAddModal(false);
    //setShowDeleteModal(false);
    setShowAddModal1(false);
    setShowAddModal2(false);
    setShowAddModal3(false);
    setShowAddModal4(false);
    setShowAddModal5(false);
    setShowAddModal6(false);
    setShowAddModal7(false);
    setShowAddModal8(false);
    setShowAddModal9(false);
  };



  const [experiences, setExperiences] = useState([]);
  const [diplomas, setDiplomas] = useState([]);
  const [skills, setSkills] = useState([]);
  const [interests, setInterests] = useState([]);
  const [languages, setLanguages] = useState([]);
  const savedExperience = localStorage.getItem('bExperience');
  const savedDiplome = localStorage.getItem('bDiplome');
  const savedCompetence = localStorage.getItem('bCompetence');
  const savedCentres = localStorage.getItem('bCentres');
  const savedLangue = localStorage.getItem('bLangues');
  
  useEffect(() => {
    // Effectuez une requête GET pour récupérer les données des expériences
         
         axios.get(`${process.env.REACT_APP_API_URL}/sbExpPro/?customUser=${UserId}`)
      .then(response => {
        console.log(response.data);
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setExperiences(response.data);

      })
      .catch(error => {
        console.error('Erreur lors de la récupération des expériences:', error);
      });
    

    // Effectuez une requête GET pour récupérer les données des diplômes
    axios.get(`${process.env.REACT_APP_API_URL}/sbDipForm/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setDiplomas(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des diplômes:', error);
      });

    // Effectuez une requête GET pour récupérer les données des compétences
    axios.get(`${process.env.REACT_APP_API_URL}/sbCompetence/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setSkills(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des compétences:', error);
      });

    // Effectuez une requête GET pour récupérer les données des centres d'intérêt
    axios.get(`${process.env.REACT_APP_API_URL}/sbCentreInteret/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setInterests(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des centres d\'intérêt:', error);
      });
    // Effectuez une requête GET pour récupérer les données des langues
    axios.get(`${process.env.REACT_APP_API_URL}/langue/?customUser=${UserId}`)
      .then(response => {
        response.data.sort(function(a, b){
          return a.position - b.position;
        });
        setLanguages(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des centres d\'intérêt:', error);
      });
  }, []);
 //Fonction qui permet de gérer le glisser-déposer au niveau des blocs d'expérience et de diplôme
 const onDragEnd = (result) => {
  const { source, destination } = result;
  // Si la destination est nulle, l'élément a été déplacé en dehors de la liste
  if (!destination) {
    return;
  }
  // Copiez les tableaux pour ne pas modifier l'état d'origine directement
  const newExperiences = [...experiences];
  const newDiplomas = [...diplomas];
  // Gérer la réorganisation pour les cartes d'expérience
  if (
    source.droppableId === "experiences" &&
    destination.droppableId === "experiences"
  ) {
    const [removed] = newExperiences.splice(source.index, 1);
    newExperiences.splice(destination.index, 0, removed);
  }
  // Gérer la réorganisation pour les cartes de diplôme
  if (
    source.droppableId === "diplomas" &&
    destination.droppableId === "diplomas"
  ) {
    const [removed] = newDiplomas.splice(source.index, 1);
    newDiplomas.splice(destination.index, 0, removed);
  }
   // Mettre à jour les états avec les nouvelles positions
   setExperiences(newExperiences);
   setDiplomas(newDiplomas);

   //Mettre à jour les positions dans la base de données
   newExperiences.map((experience, index) => (
    //formData = {position: index},
    axios.patch(`${process.env.REACT_APP_API_URL}/sbExpPro/${experience.id}/`, {position: index})
   ));
   
   //Mettre à jour les positions dans la base de données
   newDiplomas.map((diploma, index) => (
    //formData = {position: index},
    axios.patch(`${process.env.REACT_APP_API_URL}/sbDipForm/${diploma.id}/`, {position: index})
   ));
};

//Fonction qui permet de gérer le glisser-déposer au niveau du bloc de compétence
const onSkillsDragEnd = (result) => {
  const { source, destination } = result;

  // Si la destination est nulle, l'élément a été déplacé en dehors de la liste
  if (!destination) {
    return;
  }
  // Copiez les tableaux pour ne pas modifier l'état d'origine directement
  const newSkills = [...skills];
  const movedSkill = newSkills.splice(source.index, 1)[0];
  newSkills.splice(destination.index, 0, movedSkill);
  setSkills(newSkills);
  // Gérer la réorganisation pour les cartes d'expérience
  if (
    source.droppableId === "skills" &&
    destination.droppableId === "skills"
  ) {
    const [removed] = newSkills.splice(source.index, 1);
    newSkills.splice(destination.index, 0, removed);
  }
  // Mettre à jour les états avec les nouvelles positions
  setSkills(newSkills);
  //Mettre à jour les positions dans la base de données
  newSkills.map((skill, index) => (
    //formData = {position: index},
    axios.patch(`${process.env.REACT_APP_API_URL}/sbCompetence/${skill.id}/`, {position: index})
   ));

};
//Fonction qui permet de gérer le glisser-déposer au niveau du bloc du centre d'intérêt
const onInterestsDragEnd = (result) => {
  const { source, destination } = result;
  // Si la destination est nulle, l'élément a été déplacé en dehors de la liste
  if (!destination) {
    return;
  }
  // Copiez les tableaux pour ne pas modifier l'état d'origine directement
  const newInterests = [...interests];
  const movedInterest = newInterests.splice(source.index, 1)[0];
  newInterests.splice(destination.index, 0, movedInterest);

  setInterests(newInterests);
  // Gérer la réorganisation pour les cartes d'expérience
  if (
    source.droppableId === "Interests" &&
    destination.droppableId === "Interests"
  ) {
    const [removed] = newInterests.splice(source.index, 1);
    newInterests.splice(destination.index, 0, removed);
  }
  // Mettre à jour les états avec les nouvelles positions
  setInterests(newInterests);
 //Mettre à jour les positions dans la base de données
 newInterests.map((interest, index) => (
  //formData = {position: index},
  axios.patch(`${process.env.REACT_APP_API_URL}/sbCentreInteret/${interest.id}/`, {position: index})
 ));

};
//Fonction qui permet de gérer le glisser-déposer au niveau du bloc de langue
const onLanguagesDragEnd = (result) => {
  if (!result.destination) {
    return;
  }
  const newLanguages = Array.from(languages);
  const [reorderedLanguage] = newLanguages.splice(result.source.index, 1);
  newLanguages.splice(result.destination.index, 0, reorderedLanguage);
  setLanguages(newLanguages);
  //Mettre à jour les positions dans la base de données
  newLanguages.map((language, index) => (
    //formData = {position: index},
    axios.patch(`${process.env.REACT_APP_API_URL}/langue/${language.id}/`, {position: index})
   ));
};

  const flag = useRef(false);
  const [userInfo, setUserInfo] = useState({});
  const [cv, setCv] = useState({});
  const UserId = localStorage.getItem('userId');
  const cvId = localStorage.getItem('cvId');
  useEffect(() => {
    if (UserId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/user/${UserId}/`) 
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [UserId]);
  useEffect(() => {
    if (cvId) {
      // Si l'ID du CV existe dans le local storage
      axios.get(`${process.env.REACT_APP_API_URL}/cv/${cvId}/`) 
        .then((response) => {
          setCv(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user info:', error);
        });
    }
  }, [cvId]);
  const cvStyles = {
    height: "29,7cm", overflow: "hidden", fontFamily: selectedTextFont,
    fontWeight: selectedTextWeight,
  };
  
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmation2, setShowConfirmation2] = useState(false);
  const [showConfirmation3, setShowConfirmation3] = useState(false);
  const [showConfirmation4, setShowConfirmation4] = useState(false);
  const [showConfirmation5, setShowConfirmation5] = useState(false);
  const [selectedDiplomaId, setSelectedDiplomaId] = useState(null);
  const [selectedExperienceId, setSelectedExperienceId] = useState(null);
  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedLangueId, setSelectedLangueId] = useState(null);
  const [selectedInteretId, setSelectedInteretId] = useState(null);
  
  const handleDeleteBtn = (id) => {
    setSelectedExperienceId(id);
    setShowConfirmation(true);
  };
  const handleDeleteBtn2 = (id) => {
    setSelectedDiplomaId(id);
    setShowConfirmation2(true);
  };
  const handleDeleteBtn3 = (id) => {
    setSelectedSkillId(id);
    setShowConfirmation3(true);
  };
  const handleDeleteBtn4 = (id) => {
    setSelectedInteretId(id);
    setShowConfirmation4(true);
  };
  const handleDeleteBtn5 = (id) => {
    setSelectedLangueId(id);
    setShowConfirmation5(true);
  };
  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    setShowConfirmation2(false);
    setShowConfirmation3(false);
    setShowConfirmation4(false);
    setShowConfirmation5(false);
  };
  const handleConfirmationYes = () => {
    deletediplome(selectedDiplomaId)
      .then(res => {
        setDiplomas(diplomas.filter(diploma => diploma.id !== selectedDiplomaId));
        setShowConfirmation2(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du diplôme : ', error);
        setShowConfirmation2(false);
      });
  };
  const handleConfirmationYes2 = () => {
    deleteexperience(selectedExperienceId)
      .then(res => {
        setExperiences(experiences.filter(experience => experience.id !== selectedExperienceId));
        setShowConfirmation(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de l\'expérience : ', error);
        setShowConfirmation(false);
      });
  };
  const handleConfirmationYes3 = () => {
    deletecompetence(selectedSkillId)
      .then(res => {
        setSkills(skills.filter(skill => skill.id !== selectedSkillId));
        setShowConfirmation3(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de compétence : ', error);
        setShowConfirmation3(false);
      });
  };
  const handleConfirmationYes4 = () => {
    deletecentreinteret(selectedInteretId)
      .then(res => {
        setInterests(interests.filter(interest => interest.id !== selectedInteretId));
        setShowConfirmation4(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du centre d\'intérêt : ', error);
        setShowConfirmation4(false);
      });
  };
  const handleConfirmationYes5 = () => {
    deletelangue(selectedLangueId)
      .then(res => {
        setLanguages(languages.filter(language => language.id !== selectedLangueId));
        setShowConfirmation5(false);
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de langue : ', error);
        setShowConfirmation5(false);
      });
  };
  return (
    <Container className="mt-8 template-sheet " style={cvStyles}>
      <span id="modele" style={{ display: "none" }}>
        Modele3
      </span>
      <div className="sect">
        <div className="left-section" style={{
          height: "29,7cm",
          backgroundColor: bg2Color
        }}>
          <div className="profile">
            {imageUrl && (
              <img
                src={imageUrl}
                className={`profile-photo ${isPhotoRounded ? "rounded" : ""}`}
                alt=""
              />
            )}
            <div
              className="blue-box"
              style={{
                backgroundColor: bgColor,
              }}
            ></div>
          </div>
          <h2 className={`name nom ${isNameUppercase ? "uppercase" : ""
            }`}>
            {userInfo.nom} <br />
            <span
              style={{
                color: bgColor,
              }}
            >
              {userInfo.prenoms}
            </span>
          </h2>
          <p className="n-p" id="textform">{inputPoste}</p>
          <div className="infor">
            <p className="heading">Informations</p>
            {adresseVisible && (

              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputAdresse}</span>
              </p>
            )}
            {numVisible && (

              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputPhone}</span>
              </p>
            )}
            {emailVisible && (
              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputEmail}</span>
              </p>
            )}
            {datVisible && (
              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputDatNais}</span>
              </p>
            )}
            {natioVisible && (
              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputNationalite}</span>
              </p>
            )}
            {siteVisible && (
              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputSite}</span>
              </p>
            )}
            {situaVisible && (
              <p className="p1">
                <span id="textform" style={{ wordWrap: 'break-word' }}>{inputSituation}</span>
              </p>

            )}
          </div>

          <div
            className="infor inform"
            onMouseEnter={() => setShowButtons6(true)}
            onMouseLeave={() => setShowButtons6(false)}
          >
            <div className="d-flex  justify-content-between align-items-center">
              <p className="heading" style={{ fontFamily: TextFont, fontWeight: TextWeight }}>Centre d'intérêt</p>
              {showButtons6 && (
                <div className="d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Ajouter</Tooltip>}
                  >
                    <button className="btn btn-link p-1" onClick={AddInteret}>
                      <span
                        className="oi oi-plus"
                        style={{ fontSize: "1rem",color: "#fff" }}
                      ></span>
                    </button>
                  </OverlayTrigger>

                </div>
              )}
            </div>
            <DragDropContext onDragEnd={onInterestsDragEnd}>
              <Droppable droppableId="interests" direction="vertical">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>

                    {interests.map((interest, index) => (
                      <Draggable
                        key={interest.id}
                        draggableId={interest.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="d-flex"
                            onMouseEnter={() => setShowButtons7(true)}
                            onMouseLeave={() => setShowButtons7(false)}
                          >
                            <p className="p1">{interest.interet}</p>
                            {showButtons7 && (
                              <div className="d-flex">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Modifier</Tooltip>}
                                >
                                  <button className="btn btn-link p-1" onClick={() => {
                                    localStorage.setItem('Interêt_id', interest.id);
                                    ModifInteret();
                                  }}>
                                    <span
                                      className="oi oi-eyedropper"
                                      style={{
                                        fontSize: "0.9rem",
                                        color: "#fff"
                                      }}
                                    ></span>
                                  </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Supprimer</Tooltip>}
                                >
                                  <button
                                    className="btn btn-link p-1"
                                    onClick={() => { handleDeleteBtn4(interest.id); }}
                                  >
                                    <span
                                      className="oi oi-trash"
                                      style={{
                                        fontSize: "0.9rem",
                                        color: "#fff"
                                      }}
                                    ></span>
                                  </button>
                                </OverlayTrigger>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    <Modal show={showConfirmation4} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                      centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirmation de suppression</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Êtes-vous sûr de vouloir supprimer ce centre d'intérêt ?
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleConfirmationClose}>
                          Non
                        </Button>
                        <Button variant="danger" onClick={handleConfirmationYes4}>
                          Oui
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

          </div>
          <div
            className="infor inform"
            onMouseEnter={() => setShowButtons8(true)}
            onMouseLeave={() => setShowButtons8(false)}
          >
            <div className="d-flex  justify-content-between align-items-center">
              <p className="heading" style={{ fontFamily: TextFont, fontWeight: TextWeight }}>Langues</p>
              {showButtons8 && (
                <div className="d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Ajouter</Tooltip>}
                  >
                    <button className="btn btn-link p-1" onClick={AddLangue}>
                      <span
                        className="oi oi-plus"
                        style={{ fontSize: "1rem",color: "#fff" }}
                      ></span>
                    </button>
                  </OverlayTrigger>

                </div>
              )}
            </div>

            <DragDropContext onDragEnd={onLanguagesDragEnd}>
              <Droppable droppableId="languages" direction="vertical">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}

                  >
                    {languages.map((language, index) => (
                      <Draggable
                        key={language.id}
                        draggableId={language.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className="d-flex"
                            onMouseEnter={() => setShowButtons9(true)}
                            onMouseLeave={() => setShowButtons9(false)}
                          >
                            <p className="p1">{language.nom} {language.barreCompetence} %</p>
                            {showButtons9 && (
                              <div className="d-flex">
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Modifier</Tooltip>}
                                >
                                  <button className="btn btn-link p-1" onClick={ModifLangue}>
                                    <span
                                      className="oi oi-eyedropper"
                                      style={{
                                        fontSize: "0.9rem",
                                        color: "#fff"
                                      }}
                                    ></span>
                                  </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Supprimer</Tooltip>}
                                >
                                  <button
                                    className="btn btn-link p-1"
                                    onClick={() => {
                                      handleDeleteBtn5(language.id);
                                    }}
                                  >
                                    <span
                                      className="oi oi-trash"
                                      style={{
                                        fontSize: "0.9rem",
                                        color: "#fff",
                                      }}
                                    ></span>
                                  </button>
                                </OverlayTrigger>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    <Modal show={showConfirmation5} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation de suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Êtes-vous sûr de vouloir supprimer cette langue ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleConfirmationClose}>
                        Non
                      </Button>
                      <Button variant="danger" onClick={handleConfirmationYes5}>
                        Oui
                      </Button>
                    </Modal.Footer>
                  </Modal>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div
          className="right-section"
          style={{
            backgroundColor: bgColor,
            height: "29,7cm"
          }}
        >
          <div className="clearfix"></div>
          <div
            style={{ marginTop: "-8%" }}
            className="right-heading d-flex justify-content-between align-items-center inform"
            onMouseEnter={() => setShowButtons(true)}
            onMouseLeave={() => setShowButtons(false)}
          >
            <p
              className="p2"
              style={{
                color: bg3Color,
                fontFamily: TextFont, fontWeight: TextWeight
              }}
            >
              Experiences
            </p>
            {showButtons && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button
                    className="btn btn-link p-1"
                    onClick={handleAddClick}

                  >
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem",color: "#333", }}
                    ></span>
                  </button>
                </OverlayTrigger>

              </div>
            )}
          </div>
          <div className="clearfix"></div>
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(result, experiences, setExperiences)
            }
          >
            <Droppable droppableId="experienceList" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} >
                  {experiences.map((experience, index) => (
                    <Draggable
                      key={experience.id}
                      draggableId={experience.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="lr-box inform2"
                          onMouseEnter={() => setShowButtons1(true)}
                          onMouseLeave={() => setShowButtons1(false)}
                        >
                          <div className="left " style={{ marginTop: "-5%" }}>
                            <p className="p4 " style={{ fontSize: "12.5px" }}>{experience.moisDebut}/{experience.anneeDebut} - {experience.moisFin}/{experience.anneeFin}</p>
                            <p className="p5" style={{ marginTop: "-15%" }}>{experience.lieu}</p>
                          </div>
                          <div className="right " style={{ marginTop: "-5%" }}>
                            <div className="d-flex justify-content-between align-items-center ">
                              <p
                                className="p4"
                                style={{
                                  color: bg4Color,

                                }}
                              >
                                {experience.poste}
                              </p>
                              {showButtons1 && (
                                <div className="d-flex">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Modifier</Tooltip>}
                                  >
                                    <button
                                      className="btn btn-link p-1"
                                      onClick={() => {
                                        localStorage.setItem("experience_id", experience.id);
                                        handleAddClick1();
                                      }}
                                    >
                                      <span
                                        className="oi oi-eyedropper"
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "#333",
                                        }}
                                      ></span>
                                    </button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Supprimer</Tooltip>}
                                  >
                                    <button
                                      className="btn btn-link p-1"
                                      onClick={() => {
                                        handleDeleteBtn(experience.id);
                                      }}
                                    >
                                      <span
                                        className="oi oi-trash"
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "#333",
                                        }}
                                      ></span>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </div>
                            <p className="p5" style={{ marginTop: "-5%", fontWeight: 'bold' }}>{experience.nomEntreprise}</p>
                            <p className="p5" style={{ marginTop: "-5%" }}>{experience.details}</p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <Modal show={showConfirmation} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
          centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation de suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Êtes-vous sûr de vouloir supprimer cette expérience ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleConfirmationClose}>
                        Non
                      </Button>
                      <Button variant="danger" onClick={handleConfirmationYes2}>
                        Oui
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <br />

          <div
            className="right-heading d-flex  justify-content-between align-items-center inform"
            // style={{ marginTop: "5%" }}
            onMouseEnter={() => setShowButtons2(true)}
            onMouseLeave={() => setShowButtons2(false)}
          >
            <p
              className="p2"
              style={{
                color: bg3Color,
                fontFamily: TextFont, fontWeight: TextWeight
              }}
            >
              Diplômes/Formations
            </p>
            {showButtons2 && (
              <div className="d-flex">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Ajouter</Tooltip>}
                >
                  <button className="btn btn-link p-1" onClick={AddDiplome}>
                    <span
                      className="oi oi-plus"
                      style={{ fontSize: "1rem", color: "#333",}}
                    ></span>
                  </button>
                </OverlayTrigger>

              </div>
            )}
          </div>
          <div className="clearfix"></div>
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, diplomas, setDiplomas)}
          >
            <Droppable droppableId="diplomaList" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="inform2">
                  {diplomas.map((diploma, index) => (
                    <Draggable
                      key={diploma.id}
                      draggableId={diploma.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="lr-box"
/*                             style={{ marginBottom:"5px",marginTop:"3px" }}
 */                            onMouseEnter={() => setShowButtons3(true)}
                          onMouseLeave={() => setShowButtons3(false)}
                        >
                          {/* Contenu du diplôme */}
                          <div className="left" style={{ marginTop: "-2.5%" }}>
                            <p className="p4" style={{ fontSize: "12.5px" }}>{diploma.moisDebut}/{diploma.anneeDebut} - {diploma.moisFin}/{diploma.anneeFin}</p>
                            <p className="p5" style={{ marginTop: "-15%" }}>{diploma.lieu}</p>
                          </div>

                          <div className="right " style={{ marginTop: "-5%" }}>
                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                className="p4"
                                style={{
                                  color: bg4Color,
                                  marginTop: "2.5%"
                                }}
                              >
                                {diploma.nomSousDipForm}
                              </p>
                              {showButtons3 && (
                                <div className="d-flex">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Modifier</Tooltip>}
                                  >
                                    <button
                                      className="btn btn-link p-1"
                                      onClick={() => {
                                        localStorage.setItem("diplome_id", diploma.id);
                                        ModifDiplome();
                                      }}
                                    >
                                      <span
                                        className="oi oi-eyedropper"
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "#333",
                                        }}
                                      ></span>
                                    </button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Supprimer</Tooltip>}
                                  >
                                    <button
                                      className="btn btn-link p-1"
                                      onClick={() => {
                                        handleDeleteBtn2(diploma.id);
                                      }}
                                    >
                                      <span
                                        className="oi oi-trash"
                                        style={{
                                          fontSize: "0.9rem",
                                          color: "#333",
                                        }}
                                      ></span>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </div>
                            <p className="p5" style={{ marginTop: "-5%", fontWeight: 'bold' }}>{diploma.nomEcole}</p>

                          </div>
                          <div className="clearfix"></div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <Modal show={showConfirmation2} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Confirmation de suppression</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Êtes-vous sûr de vouloir supprimer ce diplôme ?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleConfirmationClose}>
                        Non
                      </Button>
                      <Button variant="danger" onClick={handleConfirmationYes}>
                        Oui
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <br />

          <div
            /* style={{ marginTop: "5px" }} */
            onMouseEnter={() => setShowButtons4(true)}
            onMouseLeave={() => setShowButtons4(false)}
          >
            <div
              className="right-heading d-flex  justify-content-between align-items-center inform"
            // style={{ marginTop: "-10%" }}
            >
              <p
                className="p2"
                style={{
                  color: bg3Color,
                  fontFamily: TextFont, fontWeight: TextWeight
                }}
              >
                Commpétences
              </p>
              {showButtons4 && (
                <div className="d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Ajouter</Tooltip>}
                  >
                    <button
                      className="btn btn-link p-1"
                      onClick={AddCompetence}
                    >
                      <span
                        className="oi oi-plus"
                        style={{ fontSize: "1rem",color: "#333",color: "#333", }}
                      ></span>
                    </button>
                  </OverlayTrigger>

                </div>
              )}
            </div>
            <div className="clearfix"></div>
            <div>
              {skills.map(skill => (
                <div className="s-box sous-bloc">
                  <div
                    className="d-flex"
                    onMouseEnter={() => setShowButtons5(true)}
                    onMouseLeave={() => setShowButtons5(false)}
                  >
                    <p className="p6" style={{
                      color: bg4Color,

                    }}>{skill.competence}</p>
                    {showButtons5 && (
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Modifier</Tooltip>}
                        >
                          <button
                            className="btn btn-link p-1"
                            onClick={() => {
                              localStorage.setItem('Competence_id', skill.id);
                              ModifCompetence();
                            }}
                          >
                            <span
                              className="oi oi-eyedropper"
                              style={{
                                fontSize: "0.9rem",
                                color: "#333",
                              }}
                            ></span>
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Supprimer</Tooltip>}
                        >
                          <button
                            className="btn btn-link p-1"
                            onClick={() => {
                              handleDeleteBtn3(skill.id);
                            }}
                          >
                            <span
                              className="oi oi-trash"
                              style={{
                                fontSize: "0.9rem",
                                color: "#333",
                              }}
                            ></span>
                          </button>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                  <p className="p4" style={{ fontSize: "15px" }} >{skill.niveauAtout} %</p>
                </div>
              ))}
              <Modal show={showConfirmation3} onHide={handleConfirmationClose} aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirmation de suppression</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Êtes-vous sûr de vouloir supprimer cette compétence ?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmationClose}>
                  Non
                </Button>
                <Button variant="danger" onClick={handleConfirmationYes3}>
                  Oui
                </Button>
              </Modal.Footer>
            </Modal>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showAddModal2}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Diplome handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal4}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Competence handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal6}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Interet handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal8}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Langue handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalAdd handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal1}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalMod handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal3}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModDiplome handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal5}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModCompetence handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal7}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModInteret handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
      <Modal
        show={showAddModal9}
        onHide={handleCloseModals}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modifier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModLangue handleClose={handleCloseModals}/>
        </Modal.Body>

      </Modal>
    </Container>
  );
};
export default Modele3;
