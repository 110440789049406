import React,{useState,useEffect} from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
export const Interet = ({handleClose}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const UserId = localStorage.getItem('userId');
  const [formData, setFormData] = useState({
    interet: '',
    details: '',
customUser:UserId
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
const int_id = localStorage.getItem('Interêt_id');
useEffect(() => {
  if (UserId) {
    // Si l'ID du CV existe dans le local storage
    axios.get(`${process.env.REACT_APP_API_URL}/sbCentreInteret/${int_id}/`) // Remplacez avec votre endpoint API qui utilise l'ID du CV
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user info:', error);
      });
  }
}, [UserId]);
  const handleUpdate = (e) => {
    e.preventDefault();

    axios
      .put(`${process.env.REACT_APP_API_URL}/sbCentreInteret/${int_id}/`, formData)
      .then((response) => {
        // Traitez la réponse du serveur ici
        Toast.fire({
          title: "Centre d'intérêt modifiée",
          text:"Veuillez actualiser la page",
          icon: "success",
        });
        setFormData({
          interet: '',
          details: '',
customUser:UserId
         });
         // Fermez le modal après la soumission
        handleClose();
      })
      .catch((error) => {
        // Gérez les erreurs ici
        Toast.fire({
          title: "Une erreur s'est produite lors de la modification",
          icon: "error",
        });
      });
  };
  return (
    <Form onSubmit={handleUpdate}>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Centre d'intérêt</Form.Label>
        <Form.Control type="text" placeholder="Centre d'intérêt" name="interet"
          value={formData.interet} onChange={handleChange}/>
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label className="fw-bold">Description</Form.Label>
        <Form.Control as="textarea" rows={4} placeholder="Description" name="details"
          value={formData.details} onChange={handleChange}/>
      </Form.Group>
      <Button
            type="submit"
            style={{
              backgroundColor: "#264a67",
              border: "none",
              marginTop:"10px"
            }}
            onClick={handleUpdate}
          >
            Modifier
          </Button>
    </Form>
  );
};
export default Interet;
