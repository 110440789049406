import React,{useState} from "react";
import styled from "styled-components";
import { AiFillHome, AiOutlineFile } from "react-icons/ai";
import { MdMenu } from "react-icons/md";
import { BsFillPenFill } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
//import { FaRegHourglassHalf } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { Navbar, Nav,Button } from "react-bootstrap";
export const Side = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleClick = () => {
    console.log("Bouton cliqué");
    setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <div>
      <Section>
        <div className="top">
          <div className="brand">
            <Navbar.Brand as={NavLink} to="/tableau">
              <span style={{ color: "#264a67", fontSize: "30px" }}>
                Kapital <span style={{ color: "#ff6600" }}>CV</span>
              </span>
            </Navbar.Brand>  
          </div>
          <div className="links">
            <ul>
              <li>
                <NavLink to="/tableau">
                  <AiFillHome />
                  <span> Tableau de bord</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/tableau/Mes_CV">
                  <AiOutlineFile />
                  Mes CV
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/tableau/Mes_Lettres">
                  <BsFillPenFill />
                  Mes Lettres
                </NavLink>
              </li> */}
              
            </ul>
          </div>
        </div>
        
      </Section>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
const Section = styled.section`
  position: fixed;
  left: 0;
  background: rgba(29, 72, 81, 0.75);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  height: 100vh;
  width: 13vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  gap: 2rem;
  .top {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    .toggle {
      display: none;
    }
    .brand {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: #ffc107;
        font-size: 2rem;
      }
      span {
        font-size: 2rem;
        color: #ffc107;
      }
    }
    .links {
      display: flex;
      justify-content: center;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;

        li {
          padding: 0.6rem 1rem;
          margin-left: -40px;
          border-radius: 0.6rem;
          opacity: 3;

          &:hover {
            background-color: #ff6600;

            a {
              color: #fff;
            }
          }
          a {
            text-decoration: none;
            display: flex;
            gap: 4px;
            color: white;
            svg {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }

  .logout {
    padding: 0.3rem 1rem;
    border-radius: 0.6rem;
    margin-left: -5px;
    &:hover {
      color: #ff6600;
    }
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: white;
      opacity: 2;
      &:hover {
        font-weight: bold;
        color: #ff6600;
      }
      span {
        margin-left: -1px;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    
    width: 100%;
    font-size:10px;
    height: max-content;
    padding: 0.5rem;
    .top {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      
      .brand {
        gap: 1rem;
        justify-content: flex-start;
      }
    }
    .top>.links {
         
      ul {
        
        li {
          padding: 0.8rem 1rem;
          margin-left: -110%;
          font-size:15px;
          border-radius: 0.6rem;
          opacity: 3;

          &:hover {
            background-color: #ff6600;

            a {
              color: #fff;
            }
          }
          a {
            text-decoration: none;
            display: flex;
            gap: 4px;
            color: white;
            svg {
              margin-top: 3px;
            }
          }
        }
      }
    }
  }
  
`;
export default Side;
