import React,{useState} from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { MDBRange } from 'mdb-react-ui-kit';
export const Competence = ({handleClose}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",

    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const UserId = localStorage.getItem('userId');
  const [formData, setFormData] = useState({
    competence: '',
    niveauAtout: 0,
    details:'',
    customUser:UserId
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API_URL}/sbCompetence/`, formData)
      .then((response) => {
        Toast.fire({
          title: "Compétence créée",
          text:'Veuillez actualiser la page',
          icon: "success",
        });
        // Traitez la réponse du serveur ici
        setFormData({
          competence: '',
          niveauAtout: '',
          details:'',
        });
        // Fermez le modal après la soumission
        handleClose();
      })
      .catch((error) => {
        // Gérez les erreurs ici
        Toast.fire({
          title: "Une erreur s'est produite lors de l'ajout",
          icon: "error",
        });
        console.error('Erreur lors de l\'envoi des données :', error);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Compétence</Form.Label>
        <Form.Control type="text" placeholder="Compétence" 
          name="competence" value={formData.competence} onChange={handleChange}/>
      </Form.Group>
      <Form.Group controlId="formMission">
        <Form.Label className="fw-bold">Niveau</Form.Label>
        <MDBRange  name="niveauAtout" value={formData.niveauAtout} onChange={handleChange} style={{accentColor:'#ff6600'}}/>  
      </Form.Group>

      <Form.Group controlId="formDescription">
        <Form.Label className="fw-bold">Description</Form.Label>
        <Form.Control type="text" placeholder="Niveau Compétence" 
          name="details" value={formData.details} onChange={handleChange}/>
      </Form.Group>
      <Button
            type="submit"
            style={{
              backgroundColor: "#264a67",
              border: "none",
              marginTop:"10px"
            }}
            onClick={handleSubmit}
          >
            Ajouter
          </Button>
    </Form>
  );
};
export default Competence;
