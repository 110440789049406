import Axios from "./caller.service.js";
//import { useNavigate } from "react-router-dom";

//const navigate = useNavigate();
let login = (loginForm) => {
    return Axios.post('/login/', loginForm)
}

let saveToken = (token) => {
    localStorage.setItem('token', token)
}

let logout = () => {
    localStorage.removeItem('token')
    //navigate("/");
}

let isLogged = () => {
    let token = localStorage.getItem('token')
    return !!token
}

let getToken = () => {
    return localStorage.getItem('token')
}

export const accountService = {
    login, saveToken, logout, isLogged, getToken
}