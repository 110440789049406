import axios from 'axios'
import { accountService } from './account.service'

const Axios = axios.create({
     /* baseURL: "http://10.0.0.29:8018/"*/
  /* baseURL: "http://127.0.0.1:8000" */
  baseURL: `${process.env.REACT_APP_API_URL}`
})

Axios.interceptors.request.use(request => {
    if (accountService.isLogged()) {
        request.headers.Authorization = 'Bearer ' + accountService.getToken()
    }
    return request
})

export default Axios;
